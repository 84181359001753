@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.checkout-container {
	min-height: 100vh;
	overflow-y: auto;
	.onboarding-container-dynamic {
		padding: 0px;
	}

	.heading {
		font-weight: 600;
		font-size: 22px;

		&-right {
			text-align: right;
		}
	}

	.header-right {
		font-size: 22px;
		color: $grayDark;
		font-weight: 600;
		text-align: right;
	}

	.title {
		font-size: 24px;
		font-weight: 700;
		color: $grayDark;
	}

	.subtitle {
		color: $grayDark;
		margin-top: 4px;
		font-size: 18px;
	}

	.resource-package-container {
		// margin-top:30px;
	}

	.packge-item-container {
		margin-top: 20px;
	}

	.package-row {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		&-heading {
			font-size: 22px;
			font-weight: 600;

			&-container{
				margin-bottom: 4px;
			}
		}
	}

	.remove-package {
		margin-top: 8px;
		font-size: 16px;
		font-style: italic;
		cursor: pointer;
		display: flex;
		align-items: center;

		.remove-label {
			display: inline;
			margin-left: 6px;
		}
	}

	&-content {
		top: 0px;
		left: 0px;
		z-index: 2;
		width: 100%;
		min-height: 100vh;
		display: flex;
		// overflow-y: auto;
		position: absolute;
		// text-align: center;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		padding: calc(58px + 32px) 0;
	}

	&-wrapper {
		height: 100vh;
		margin: 0px auto;
		max-width: 500px;
	}

	&-title {
		font-size: 24px;
		font-weight: 600;
		max-width: 320px;
		margin: 0px auto;
		line-height: 28px;
		color: $blackDark;
		margin-bottom: 48px;
	}

	&-label {
		font-size: 140px;
		line-height: 16px;
		color: $blackDark;
		margin-bottom: 4px;
		font-weight: normal;
	}

	&-input {
		text-align: initial;
		margin-bottom: 44px;

		&:last-child {
			margin-bottom: 0px;
		}
	}

	&-field {
		width: 100%;
		border: none;
		padding: 8px 12px;
		background-color: transparent;
		border-bottom: 1px solid rgba(34, 34, 34, 0.2);
	}

	&-subtitle {
		margin-bottom: 80px;
	}

	.primary-button {
		margin: 0px auto;
	}

	&-packages {
		width: 80%;
	}
}

.checkout-wrapper {
	width: 40%;
	padding: 32px;
	flex-direction: column;
	// background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='34' ry='34' stroke='%230492F1FF' stroke-width='4' stroke-dasharray='21%2c 26' stroke-dashoffset='20' stroke-linecap='round'/%3e%3c/svg%3e");
	border-radius: 34px;
	border: 1px dashed #0492f1;

	&-header {
		width: 100%;
	}
}

.coupon-container {
	width: 100%;
	align-self: center;
	border-radius: 10px;
	background-color: #ecf2f5;
	margin-top: 30px;
	padding: 18px 60px;
	padding-bottom: 30px;
	border-radius: 20px;

	.coupon-content {
		h5 {
			font-size: 20px;
			font-weight: 600;
			max-width: 320px;
			margin: 0px auto;
			line-height: 28px;
			color: $blackDark;
			margin-bottom: 48px;
			text-align: left;
		}

		&-input {
			display: flex;
			align-items: center;

			input {
				border: none;
				font-size: 18px;
				background: transparent;
				border-bottom: 2px solid $black;
			}
		}

		.coupon-btn {
			// background: linear-gradient(148deg, rgba(88, 242, 233, 1) -48px, rgba(3, 144, 242, 1) 100%);
			// color: $white;
			background: none;
			color: $blueDark;
			// margin:10px;
			// padding: 6px 30px;
			font-size: 18px;
			font-weight: 600;
			border: none;
			border-radius: 10px;
		}

		.applied {
			&-container {
				display: flex;
				align-items: center;
				margin-left: -10px;

				img {
					cursor: pointer;
				}
			}

			&-text {
				font-size: 18px;
				margin: 0;
				margin-left: 10px;
				color: $greenDark;
			}
		}
	}
}

.summary {
	margin-top: 50px;

	.title {
		font-size: 18px;
		color: $grayDark;
	}
}

.total {
	font-size: 20px;
	color: $grayDark;
	font-weight: 900;
}

.breakup-container {
	display: flex;
	align-items: flex-end;
	flex-direction: column;
	width:100%;
	// justify-content: center;

	.bottom-border {
		width: 100%;
		border: 1px dashed;
		margin-bottom: 16px;
	}
}

.checkout-footer {
	margin-top: 30px;
	font-size: 20px;
	b {
		color: $greenDark;
	}
}

.value {
	margin-left: 14px;
}

.pay-btn {
	margin-top: 40px;
}
