@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.product {
  background-color: $white;
  box-shadow: 0px 6px 12px #00345817;
  border-radius: 20px;
  flex-basis: calc(100% / 4 - 30px);

  &-image-container {
    width: 100%;
    height: 140px;
  }

  &-image {
    width: 100%;
    height: 100%;
    object-fit: fill;
    border-radius: 20px;
  }

  &-detail {
    padding: 14px 14px 16px 14px;
    position: relative;
  }

  &-title {
    font-size: 18px;
    color: #212426;
    font-weight: 600;
    min-height: 80px;
  }

  &-price-cart-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 32px;
  }

  &-type-container {
    margin-top: 12px;
    bottom: 6px;
    width: 100%;
    padding: 0 4px;
  }

  &-price {
    font-weight: 700;
    font-size: 16px;
    color: #8892a6;
    margin: 0;

    p {
      margin: 0;
    }
  }

  &-description {
    font-size: 14px;
    font-weight: 600;
    color: #8892a6;

    p {
      margin: 0px !important;
      word-break: break-word;
    }
  }

  .cart-icon {
    cursor: pointer;
  }
}
