@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

.global-header-right {
  .hamburger-menu {
    margin-left: 24px;
    position: relative;
    display: inline-block;

    &-items {
      z-index: 999;
      display: flex;
      margin-top: 0px;
      position: absolute;
      right: -5px;
      flex-direction: column;
      background-color: white;
      padding: 10px 24px;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      border-radius: 8px;

      &::before,
      &::after {
        content: '';
        position: absolute;
        bottom: 100%;
        right: 19px;
        border: 11px solid transparent;
        border-bottom-color: #dddddd;
      }
      &:after {
        right: 20px;
        border: 10px solid transparent;
        border-bottom-color: $white;
      }

      a {
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;
        padding: 8px 0;
        color: $blackLight;

        &:active,
        &:hover,
        &:focus {
          color: $blueDark;
          text-decoration: none;
        }
      }
    }
  }

  .hamburger-icon {
    width: 24px;

    &-line {
      height: 2px;
      margin-bottom: 6px;
      background-color: #212426;

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}
