@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.resourcedetails-container {
	&-header {
		width: 100%;
		display: flex;
		padding-left: 40px;
		align-items: center;
		margin-bottom: 48px;

		.primary-button {
			padding: 8px 20px;
			font-weight: 600;
		}
	}

	.project-content {
		margin: 0;
		&-left {
			width: 100%;
			display: flex;
			flex-direction: column;
			padding: 0px 20px 0px 40px;

			&-title {
				color: $black;
				font-size: 24px;
				font-weight: 700;
				line-height: 28px;
				margin-bottom: 24px;
			}

			&-description {
				color: $black;
				font-size: 20px;
				font-weight: 400;
				line-height: 24px;
				margin-bottom: 4px;
			}
		}

		.upload-icon {
			width: 20px;
		}

		&-right {
			// padding: 0px 40px 0px 20px;
			padding: 0 40px 0 0;

			img {
				border-radius: 20px;
				width: 100%;
			}
		}
	}

	.project-upload {
		margin-top: 32px;

		&-cta {
			flex: 1 1;
			border: none;
			display: flex;
			cursor: pointer;
			padding: 8px 24px;
			user-select: none;
			width: max-content;
			align-items: center;
			border-radius: 10px;
			justify-content: center;
			box-shadow: 0px 8px 20px rgba(5, 144, 239, 0.25);
			background: linear-gradient(148deg, rgba(88, 242, 233, 1) -48px, rgba(3, 144, 242, 1) 100%);

			.label {
				color: $white;
				font-size: 18px;
				line-height: 28px;
				padding: 0 20px;
				margin: 0px !important;
			}
		}

		&-icon {
			flex: 1 1;
			cursor: pointer;
			user-select: none;
		}

		&-input {
			width: 1px;
			opacity: 0;
			height: 1px;
			top: -9999px;
			left: -9999px;
			position: absolute;
		}

		&-header {
			width: 100%;
			display: flex;
			align-items: center;
			margin-bottom: 24px;

			&-title {
				color: $black;
				font-size: 18px;
				font-weight: 700;
				line-height: 28px;
				margin-right: 16px;
				margin-bottom: 0px !important;
			}
		}

		&-attachments {
			width: 100%;
			display: flex;
			margin-left: 0px;
			align-items: center;
			margin-bottom: 32px;
			justify-content: space-between;
		}
	}
}

.attachment-item {
	display: flex;
	max-width: 50%;
	border-radius: 5px;
	align-items: center;
	margin-bottom: 12px;
	padding: 4px 6px !important;
	background-color: #f3f4f6;
	justify-content: space-between;

	&-number {
		padding: 8px;
		color: $black;
		font-size: 18px;
		font-weight: 400;
		line-height: 20px;
		border-radius: 5px;
		background-color: $white;
		margin-bottom: 0px !important;
	}

	&-name {
		color: $black;
		max-width: 60%;
		font-size: 18px;
		overflow: hidden;
		font-weight: 400;
		line-height: 20px;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	&-remove {
		cursor: pointer;
	}
}
