@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.textarea-container {
  border-radius: 10px;
  background: $grayLight;

  span {
    display: flex;
    padding: 4px 8px;
    color: $blueDark;
    align-items: center;
    justify-content: flex-end;
  }
}

.input-textarea {
  width: 100%;
  border: none;
  display: flex;
  font-size: 16px;
  line-height: 28px;
  padding: 8px 16px;
  padding-top: 12px;
  align-items: center;
  border-radius: 10px;
  background: $grayMedium;

  &:focus {
    outline: none;
  }
}
