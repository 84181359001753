@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.news {
	width: 100%;
	min-height: 54px;
	background-color: rgba(136, 146, 166, 0.5);
	padding: 20px 0;
	margin-bottom: 54px;
	z-index: 99;
	top: 80px;

	&-text {
		text-align: center;
		margin-bottom: 0px;
		justify-content: center;
		color: $grayDark;
		font-weight: bold;

		a {
			text-decoration: underline;
			color: $grayDark;
			margin-left: 6px;
		}
	}
}
