@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.otpentry-container {
  &-content {
    top: 0px;
    z-index: 2;
    left: 600px;
    display: flex;
    min-height: 100vh;
    position: absolute;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: calc(100% - 600px);

    @include laptopDisplay {
      left: 35%;
      width: 65%;
    }
  }

  &-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
    color: $blackDark;
    margin-bottom: 0px;
  }

  &-mobile {
    color: $black;
    font-size: 18px;
    font-weight: bold;
    line-height: 26px;
    margin-bottom: 72px;
  }

  &-label {
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
    color: $blackDark;
    margin-bottom: 32px;
  }

  &-input {
    margin: 8px 0px;
  }

  &-inputwrapper {
    max-width: 35%;
    margin: 0px auto;
    margin-bottom: 24px;
  }

  &-field {
    width: 100%;
    min-width:35px;
    border: none;
    margin: 0px 10px;
    padding: 8px 4px;
    text-align: center;
    background-color: transparent;
    border-bottom: 1px solid rgba(34, 34, 34, 0.2);
  }

  &-fallback {
    font-size: 20px;
    line-height: 28px;
    color: $blackLight;
    margin-bottom: 80px;
    font-weight: normal;

    span {
      display: inline-block;
    }

    .fallback-text {
      margin-right: 8px;
    }

    .fallback-timer {
      color: $black;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
  }
}
