@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.primary-button {
  border: none;
  color: $white;
  display: flex;
  font-size: 20px;
  min-width: 200px;
  line-height: 28px;
  font-weight: bold;
  padding: 8px 24px;
  padding-top: 12px;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  box-shadow: 0px 8px 20px rgba(5, 144, 239, 0.25);
  background: linear-gradient(148deg, rgba(88, 242, 233, 1) -48px, rgba(3, 144, 242, 1) 100%);
  cursor: pointer;

  &-icon {
    width: 24px;
    height: 12px;
    margin-left: 8px;
  }

  &:hover {
    .primary-button-icon {
      transform: translateX(10px);
      transition: transform 0.4s;
    }
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.75;
  }
}