@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.mcq-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  width:100%;

  .content-scroller {
    display: flex;
    overflow-y: auto;
    max-height: calc(100vh - 80px);
  }

  &-content {
    padding: 24px 40px;
    width:100%;
  }

  &-item {
    margin-top: 30px;
  }

  &-footer {
    padding: 12px 40px;
    margin-top:32px;
  }

  .mcq-text-item {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    label {
      // width: 100%;
      margin-left: 16px;
      margin-bottom: 0px;
      font-size: 18px;
      font-weight: 600;
      text-align: justify;
      cursor: pointer;

      img {
        max-width: 170px;
        margin-right: 50px;
      }
    }

    .answer-radio {
      cursor: pointer;

      &:after {
        width: 20px;
        height: 20px;
        border-radius: 20px;
        top: -4px;
        left: -4px;
        position: relative;
        background-color: lightgray;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid white;
      }

      &:checked:after {
        background-color: $greenDark;
      }

      &-wrong:after {
        background-color: $redDark;
      }

      &-wrong:checked:after {
        background-color: $redDark;
      }
    }
  }
}