@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.my-products {
	padding: 0 40px;
	padding-bottom: 50px;

	&-search {
		margin-top: 24px;

		.search-input {
			width: 100%;
			height: 40px;
			border: none;
			padding: 0 25px;
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;
			border-bottom: 1px solid #bed6e1;
			background-color: $grayMedium;
		}
	}

	&-grid {
		display: grid;
		margin-top: 60px;
		grid-row-gap: 50px;
		grid-column-gap: 20px;
		grid-template-columns: repeat(4, 1fr);

		@include largeDisplay {
			grid-template-columns: repeat(5, 1fr);
		}
	}

	&-empty-state {
		.img {
			max-width: 130px;
		}
	}
}
