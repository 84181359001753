@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.dashboard {
	padding: 0 40px;
	padding-bottom: 50px;

	.search {
		&-form {
		}

		&-input {
			width: 100%;
			background-color: $grayMedium;
			height: 40px;
			border: none;
			border:1px solid transparent;
			border-bottom-color: #bed6e1;
			border-radius: 5px;
			padding: 0 25px;
			padding-top: 2px;
		}
	}

	.section {
		margin-top: 72px;
	}

	.subjects-continue {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
	}

	.my-subjects {
		flex-basis: 58%;

		&-list {
			display: flex;
			margin-top: 30px;
		}

		&-icon {
			&:not(:first-child) {
				margin-left: 40px;
			}
		}
	}

	.dashboard-purchased-section {
			// background-color: red;
			padding:40px 20px;
			margin-top: 70px;
			border-radius:20px;
			// border: 1px solid $blueDark;
			background-color: $grayMedium;
			box-shadow: 0px 0px 18px 2px rgba($black, 0.05);
	}

	.continue-section {
		flex-basis: 34%;
		padding: 16px;
		border: 1px dashed #0492f1;
		background-color: $white;
		// background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='34' ry='34' stroke='%230492F1FF' stroke-width='4' stroke-dasharray='21%2c 26' stroke-dashoffset='20' stroke-linecap='round'/%3e%3c/svg%3e");
		border-radius: 32px;

		.subtitle {
			font-size: 16px;
			margin-bottom: 0;
			text-align: left;
			font-weight: 600;
			color: #212426;
			opacity: 1;
		}

		.subject {
			text-align: left;
			font-weight: 700;
			color: #212426;
			opacity: 1;
			font-size: 18px;
			margin-top: 20px;
			word-break: break-word;
		}

		.resource-type {
			color: #212426;
			text-align: left;
			font-weight: 600;
			font-size: 14px;
			margin-bottom: 0px !important;
		}

		.link {
			color: #8892a6;
			margin-top: 30px;
			display: flex;
			align-items: center;
			font-size: 12px;

			img {
				margin-left: 6px;
				width: 12px;
				height: 12px;
				margin-top: -0.8px;
			}

			&:hover {
				img {
					margin-left: 12px;
					transition: all 0.5s;
				}
			}
		}

		.progress-content {
			display: flex;
			justify-content: space-between;
		}

		.circular-progress {
			& > div {
				display: flex;
			}

			.numerator {
				&::after {
					content: '/';
					padding: 0 8px;
				}
			}
		}
	}

	&-image-banner {
		width: 100%;
		height: 160px;
		margin-top: 80px;

		.banner-image {
			width: 100%;
			height: 160px;
			object-fit: cover;
		}
	}
}

.product-list {
	display: flex;
}

.products-container {
	display: flex;
	margin-top: 22px;

	.product-list {
		flex-basis: 80%;

		.product {
			flex-basis: calc(100%/3 - 25px);

			&:not(:first-child) {
				margin-left: 20px;
			}
		}
	}
}

.view-all-products {
	flex-basis: 20%;
	box-shadow: 0px 10px 40px #00345817;
	display: inline-block;
	border-radius: 20px;
	position: relative;

	.content {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
		text-align: center;
	}

	.all-products-arrow {
		background-color: red; // TODO: change to white , when get the blue icon
		// position: absolute;
		position: relative;
		height: 42px;
		width: 42px;
		border-radius: 50%;
		left: 50%;
		transform: translateX(-50%);

		.icon {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	.label {
		margin-top: 10px;
		font-size: 18px;
		font-weight: 600;
		color: #212426;
	}
}

.sessions {
	position: relative;

	.product {
		&:not(:first-child) {
			margin-left: 20px;
		}
	}

	.btn-container {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 100%;
	}

	.btn {
		background-color: red; // TODO: change to white , when get the blue icon
		position: absolute;
		height: 42px;
		width: 42px;
		border-radius: 50%;

		.icon {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	.right-btn {
		right: 60px;
	}

	.left-btn {
		left: 30px;
		transform: rotate(180deg);
	}

	&-card {
		width: 100%;
		flex-basis: calc(100%/4 - 30px);

		&:not(:first-child) {
			margin-left: 20px;
		}
	}
}

.scrollable-list {
	display: flex;
	margin-top: -10px;
	overflow-x: scroll;
	padding: 40px 0;
	scroll-behavior: smooth;

	&::-webkit-scrollbar {
		display: none;
	}

	.product {
		flex-basis: calc(100% / 4);
		flex-shrink: 0;
	}

	&::after {
		content: '';
		position: absolute;
		right: 0;
		right: 0;
		height: 100%;
		width: 200px;
		background: linear-gradient(to left, $white 20%, transparent 100%);
	}
}

.advert {
	margin-top: 60px;

	&-list {
		display: flex;
		margin-top: 22px;

		.label {
			font-weight: 600;
		}
	}

	&-item {
		&:not(:first-child) {
			margin-left: 20px;
		}
	}
}
