@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

.membership {
  width: 100%;
  height: auto;
  display: flex;
  padding: 40px;
  // height: 90vh;
  overflow-y: auto;
  flex-direction: column;

  &-header {
    width: 100%;
    margin-bottom: 32px;

    &-title {
      color: $black;
      font-size: 20px;
      font-weight: 700;
    }
  }

  &-content {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;

    &-item {
      width: 100%;
      display: flex;
      padding:16px 24px;
      border-radius: 10px;
      align-items: center;
      margin-bottom: 32px;
      background-color: $white;
      border: 1px dashed #0492f1;
      // background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='34' ry='34' stroke='%230492F1FF' stroke-width='4' stroke-dasharray='21%2c 26' stroke-dashoffset='20' stroke-linecap='round'/%3e%3c/svg%3e");
    }
  }

  &-footer {
    display: flex;
    margin: 60px 0px;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    &-title {
      color: $black;
      font-size: 20px;
      font-weight: 700;

      span {
        color: #0C9BF0;
      }
    }
  }

  &-banner {
    width: 100%;
    margin-top: 80px;
    
    &-image {
      width: 100%;
      height: 160px;
      object-fit: cover;
    }
  }
}

.package-details {
  width: 40%;
  display: flex;
  flex-direction: column;

  &-title {
    font-size: 18px;
    font-weight: 700;
    color: #8892a6;
    margin-bottom: 12px !important;
  }

  &-subtitles {
    display: flex;
    margin-bottom: 8px;
    align-items: center;

    p {
      font-size: 16px;
      font-weight: 600;
      color: #8892a6;
      margin-bottom: 0px;

      &:nth-child(1) {
        margin-right: 24px;
      }
    }
  }

  &-package {
    color: $black;
    font-size: 20px;
    font-weight: 700;
    margin-top: 12px;
    margin-bottom: 24px !important;

    span {
      font-size: 20px;
      font-weight: 700;
    }
  }

  &-date {
    color: $black;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px !important;
  }
}

.package-icons {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &-item {
    display: flex;
    border-radius: 20px;
    align-items: center;
    flex-direction: column;
    padding: 0px 32px 16px 32px;
    box-shadow: 0px 10px 40px #00345817;
    background: #ffffff 0% 0% no-repeat padding-box;

    h5 {
      margin-top: 16px;
      font-size: 16px;
      font-weight: 600;
      color: #5f5f5f;
      letter-spacing: 0px;
      margin-bottom: 0px !important;
    }
  }

  &-separator {
    color: #212426;
    font-size: 28px;
    padding: 20px;
    margin-bottom: 0px !important;
  }
}
