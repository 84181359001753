@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.all-products {
	padding: 0 40px;
	padding-bottom: 50px;

	&-grid {
		margin-top: 80px;
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-column-gap: 20px;
		grid-row-gap: 50px;

		@include largeDisplay {
			grid-template-columns: repeat(5, 1fr);
		}
	}
}
