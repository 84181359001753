@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

.global-loader {
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 99999;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.85);

  &-image {
    width: 180px;
    height: 180px;
  }

  &-text {
    color: $black;
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
  }
}
