@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.allsessions {
	padding: 0 40px;

	&-body {
		width: 100%;
		// height: 480px;
		// display: flex;
		// flex-wrap: wrap;
		margin-top: 32px;
		align-items: center;
		border-radius: 20px;

		&-left {
			height: 100%;
			display: flex;
			// max-width: 327px;
			align-items: center;
			padding: 0 !important;
			// flex-direction: column;
			justify-content: center;
			background-color: $grayMedium;
			border-right: 1px solid $white;
			border-radius: 12px;

			& > div {
				flex: 1;
			}
		}

		&-right {
			width: 100%;
			height: 100%;
			padding: 0 20px;
			padding-top: 10px;
			overflow-y: auto;
			margin-top: 30px;
			position: relative;
			min-height:60vh;
			.content {
				display: grid;
				grid-template-columns: repeat(4, 1fr);
				grid-gap: 20px;
        padding-bottom: 14px;
			}
		}
	}

	.session-msg {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 18px;
		font-weight: 600;
	}

	.session-type {
		width: 100%;
		display: flex;
		padding: 16px;
		cursor: pointer;
		align-items: center;
		// padding-left: 20%;
		justify-content: center;

		.chevron {
			position: absolute;
			right: 30px;

			img {
				height: 15px;
				width: 15px;
			}
		}

		&-info {
			margin-left: 24px;
		}

		&-title {
			color: $black;
			font-size: 20px;
			font-weight: 700;
			line-height: 24px;
			margin-bottom: 0px;
			margin-bottom: 0 !important;
		}

		&-subtitle {
			font-size: 14px;
			font-weight: 400;
			line-height: 20px;
			color: $blackLight;
			margin-top: 8px;
			font-weight: 600;
			margin-bottom: 0px;
		}

		// &:first-child {
		// 	border-top-left-radius: 15px;
		// }

		// &:last-child {
		// 	border-bottom-left-radius: 15px;
		// }
	}

	.session-type-selected {
		// background: linear-gradient(to bottom, rgba($white, 0.2), rgba(#0694f1, 0.2));
		background-color: $blueDark;
    border-radius: 12px;

		.session-type-title {
			color: $white;
			font-weight: 700;
		}

		&-subtitle {
			font-weight: 400;
			line-height: 20px;
			color: #47aef4;
		}

		&:first-child {
			border-top-left-radius: 15px;
		}

		&:last-child {
			border-bottom-left-radius: 15px;
		}

		&--selected {
			background-color: #e3eef7;
		}
	}
}
