@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.cartcheckout {
  padding: 0 40px;
  padding-bottom: 50px;

  &-content {
    width: 100%;
    display: flex;
    margin-top: 60px;
    flex-direction: column;

    &-header {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 8px;
      padding-bottom: 8px;
      align-items: center;

      h5 {
        color: #212426;
        font-size: 14px;
        margin-bottom: 0px !important;
      }
    }

    &-item {
      display: flex;
      flex-wrap: wrap;
      margin-top: 16px;
    }

    &-separator {
      margin: 40px 0px;
      border: 1px dashed #8892a6;
    }

    .separator-two{
      margin:26px 0;
    }

    &-breakdown {
      display: flex;
      flex-wrap: wrap;

      .breakdown-fields {
        display: flex;
        align-items: flex-end;
        flex-direction: column;

        h5 {
          color: $black;
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 16px !important;
        }
      }

      .breakdown-values {
        display: flex;
        flex-direction: column;

        h5 {
          color: $black;
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 16px !important;
        }
      }
    }

    &-total {
      display: flex;
      flex-wrap: wrap;

      h5 {
        color: $black;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 0px !important;

        &:nth-child(1) {
          text-align: end;
        }
      }
    }

    &-cta {
      display: flex;
      margin-top: 100px;
      align-items: center;
      justify-content: center;
    }
  }

  .productprice {
    color: $black;
    font-size: 20px;
    font-weight: 700;
  }

  .productitem {
    display: flex;

    &-body {
      width: 100%;
      display: flex;
      flex-direction: column;

      &-title {
        color: $black;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 10px !important;
      }

      &-subtitle {
        color: $black;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 0px !important;
      }
    }
  }
}
