@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';


.parent-dashboard {
  padding: 0 40px;
  padding-bottom: 50px;

  .search {
    &-form {}

    &-input {
      width: 100%;
      background-color: rgba(242, 246, 248, 0.8);
      height: 40px;
      border: none;
      border-bottom: 1px solid #bed6e1;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      padding: 0 25px;
    }
  }

  .section {
    margin-top: 72px;
  }

  .subjects-continue {
    display: flex;
    justify-content: space-between;
  }

  .my-subjects {
    flex-basis: 60%;

    &-list {
      display: flex;
      margin-top: 30px;
    }

    &-icon {
      &:not(:first-child) {
        margin-left: 40px;
      }
    }
  }

  .continue-section {
    flex-basis: 40%;
    padding: 22px 25px;
    // border: 1px dashed #0492f1;
    // background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='34' ry='34' stroke='%230492F1FF' stroke-width='4' stroke-dasharray='21%2c 26' stroke-dashoffset='20' stroke-linecap='round'/%3e%3c/svg%3e");
    border-radius: 34px;
    border: 1px dashed #0492f1;

    .subtitle {
      font-size: 18px;
      margin-bottom: 0;
      text-align: left;
      font-weight: 600;
      color: #212426;
      opacity: 1;
    }

    .subject {
      text-align: left;
      font-weight: 900;
      color: #212426;
      opacity: 1;
      font-size: 24px;
      margin-top: 30px;
      word-break: break-word;
    }

    .resource-type {
      color: #212426;
      text-align: left;
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 0px !important;
    }

    .link {
      color: #8892a6;
      margin-top: 30px;
      display: inline-block;
    }

    .progress-content {
      display: flex;
      justify-content: space-between;
    }

    .circular-progress {
      &>div {
        display: flex;
      }

      .numerator {
        &::after {
          content: '/';
          padding: 0 8px;
        }
      }
    }
  }

  .dashboard-image-banner {
    width: 100%;
    height: 160px;
    margin-top: 80px;

    .banner-image {
      width: 100%;
      height: 160px;
      object-fit: cover;
    }
  }

  .product-list {
    display: flex;
  }

  .products-container {
    display: flex;
    margin-top: 30px;

    .product-list {
      flex-basis: 80%;

      .product, .upload-card {
        flex-basis: calc(100%/3 - 25px);

        &:not(:first-child) {
          margin-left: 20px;
        }
      }
    }
  }

  .view-all-products {
    flex-basis: 20%;
    box-shadow: 0px 10px 40px #00345817;
    display: inline-block;
    border-radius: 20px;
    position: relative;

    .content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      text-align: center;
    }

    .all-products-arrow {
      background-color: red; // TODO: change to white , when get the blue icon
      // position: absolute;
      position: relative;
      height: 42px;
      width: 42px;
      border-radius: 50%;
      left: 50%;
      transform: translateX(-50%);

      .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .label {
      margin-top: 10px;
      font-size: 18px;
      font-weight: 600;
      color: #212426;
    }
  }

  .sessions {
    position: relative;

    .product {
      &:not(:first-child) {
        margin-left: 20px;
      }
    }

    .btn-container {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
    }

    .btn {
      background-color: red; // TODO: change to white , when get the blue icon
      position: absolute;
      height: 42px;
      width: 42px;
      border-radius: 50%;

      .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .right-btn {
      right: 60px;
    }

    .left-btn {
      left: 30px;
      transform: rotate(180deg);
    }

    &-card {
      width: 100%;
      flex-basis: calc(100%/4 - 30px);

      &:not(:first-child) {
        margin-left: 20px;
      }
    }
  }

  .scrollable-list {
    display: flex;
    margin-top: -10px;
    overflow-x: scroll;
    padding: 40px 0;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      display: none;
    }

    .product {
      flex-basis: calc(100% / 4);
      flex-shrink: 0;
    }

    &::after {
      content: '';
      position: absolute;
      right: 0;
      right: 0;
      height: 100%;
      width: 200px;
      background: linear-gradient(to left, $white 20%, transparent 100%);
    }
  }


  .advert {
    margin-top: 40px;

    &-list {
      display: flex;
      margin-top: 30px;

      .label{
        font-weight: 600;
      }
    }

    &-item {
      &:not(:first-child) {
        margin-left: 20px;
      }
    }
  }

  .subject-progress-row {
    display: grid;
    cursor: pointer;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    margin-top: 40px;

    // @include largeLaptopDisplay {
    //   grid-template-columns: repeat(3, 1fr);
    //   grid-row-gap: 20px;
    //   margin-top: 40px;
    // }
  }
}