.global-header {
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 999;
  display: flex;
  position: fixed;
  align-items: center;
  backdrop-filter: blur(32px);
  padding: 8px 48px;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.4);

  &-logo {
    max-height: 60px;
  }
}
