@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.fitbs-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  width:100%;

  .content-scroller {
    display: flex;
    overflow-y: auto;
    max-height: calc(100vh - 80px);
  }

  &-content {
    padding: 24px 40px;
    width: 100%;

    .fitbs-image {
      max-width: 300px;
      max-height: 200px;
      align-self: center;
      border-radius: 10px;
      margin-bottom: 24px;
      display: block;
      margin-top: 20px;
      cursor: pointer;
    }

    .fitbs-text {
      color: $black;
      font-size: 18px;
      font-weight: 600;
      line-height: 22px;
      margin-bottom: 14px;
      text-align: justify;
      display: inline-block;
    }

    .fib-heading {
      font-weight: 700;
    }

    .fitbs-field {
      // display: flex;
      margin-bottom: 24px;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  &-item {
    margin-top: 16px;
  }

  &-footer {
    padding: 32px 40px;
    margin-top: 32px;

    .primary-button {
      display: flex;
      align-items: center;
      font-weight: 600;
      padding: 8px;
      cursor: pointer;

      &-icon {
        margin: 0;
        margin-left: 10px;
      }
    }
  }

  .fitbs-item {
    width: 100%;
    padding: 8px;
    display: flex;
    margin: 0 16px;
    border-radius: 10px;
    align-items: center;
    background-color: $grayMedium;
    display: inline-block;
    max-width: 200px;

    img {
      width: 100%;
      height: 100%;
    }

    &--faded {
      color: grey;
    }
  }
}