@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

.upload-card {
	display: flex;
	cursor: pointer;
	align-items: center;
	border-radius: 10px;
	flex-direction: column;
	padding: 0px !important;
	background-color: $white;
	max-height: 250px;
	overflow: hidden;
	box-shadow: 0px 10px 40px #00345817;

	.res-info {
		padding: 14px;
    margin-left: 0;
	}

	&-icon {
		width: 100%;
		height: 120px;
		object-fit: cover;
    border-radius: 20px;
	}

	&-title {
		color: $black;
		font-size: 16px;
		font-weight: 600;
		line-height: 24px;
		margin-bottom: 0px !important;
    text-align: left;
	}

	&-description {
		font-size: 12px;
		font-weight: 400;
		margin-top: 8px;
		line-height: 16px;
		color: $blackLight;
		margin-bottom: 0px;
	}
}
