@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.subjectdetails {
	&-container {
		width: 100%;
		height: auto;
		display: flex;
		padding: 40px;
		padding-top: 20px;
		// overflow-y: auto;
		flex-direction: column;
		// position: relative;
	}

	&-header-container {
		display: flex;
		justify-content: space-between;
		margin-bottom: 46px;

		.continue-section {
			flex-basis: 50%;
			padding: 12px 16px;
			background: $white;
			// border: 1px dashed #0492f1;
			// background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='34' ry='34' stroke='%230492F1FF' stroke-width='4' stroke-dasharray='21%2c 26' stroke-dashoffset='20' stroke-linecap='round'/%3e%3c/svg%3e");
			border-radius: 28px;
			border: 1px dashed #0492f1;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.left {
				flex: 1;
			}

			.progress-content {
				flex: 1;
				text-align: right;
			}

			.progress-value {
				display: flex;
				align-self: flex-end;
				justify-content: flex-end;

				h2 {
					font-size: 28px;
					font-weight: 900;
				}
			}

			.num {
				color: $blueDark;

				&:after {
					content: '/';
					margin: 0 10px;
				}
			}

			.den {
				color: #31c5ed;
			}

			.subtitle {
				font-size: 16px;
				margin-bottom: 0;
				text-align: left;
				font-weight: 600;
				color: #8892a6;
				opacity: 1;
			}

			.subject {
				text-align: left;
				font-weight: 700;
				color: #212426;
				opacity: 1;
				font-size: 18px;
				margin-top: 16px;
			}

			.link {
				color: #8892a6;
				margin-top: 6px;
				font-size: 12px;
				text-decoration: underline;
				display: inline-block;
			}

			.info {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}

			.resource-type {
				color: #212426;
				text-align: left;
				font-weight: 600;
				font-size: 16px;
				margin-bottom: 0px !important;
			}
		}
	}

	&-header {
		width: 100%;
		display: flex;
		margin-bottom: 32px;
		align-items: center;
		flex-basis: 60%;

		&-title {
			color: $black;
			font-weight: 700;
			line-height: 28px;
			margin-left: 16px;
			font-size: 28px;
			margin-bottom: 0;
		}

		&-icon {
			width: 60px;
			height: 60px;
			border-radius: 12px;
		}
	}

	&-body {
		position: relative;
		min-height: 50vh;

		.row {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		&-header {
			border-bottom: 1px solid #9a9a9b;
			padding-bottom: 12px;

			h5 {
				flex-basis: 60%;
			}

			.input-container {
				flex-basis: 40%;

				input {
					background-color: $grayMedium;
				}
			}
		}

		.lesson {
			&-heading-container {
				display: flex;
				align-items: center;
				flex: 1;
			}

			&-icon {}

			&-title {
				font-size: 20px;
				flex: 1;
				margin-bottom: 0 !important;
				margin-left: 12px;
				color: $black;
				font-weight: 600;
			}
		}

		.items-container {
			margin-top: 30px;
			// min-height: 50vh;

			.empty-state {
				// transform: translate(-50%, -50%);
				top: 76%;
				left: 40%;

				.img {
					max-width: 120px;
				}
			}
		}

		.dividing-line {
			position: absolute;
			height: 88%;
			width: 2px;
			top: 96px;
			left: 50%;
			background-color: rgba($black, 0.1);
			transform: translateX(-50%);
		}

		.lesson-item {
			margin: 8px;
			// padding: 8px;
			display: flex;
			position: relative;
			cursor: pointer;
			border: 1px solid $grayLight;
			border-radius: 10px;
			background-color: $white;
			align-items: center;
			box-shadow: 2px 2px 10px 2px rgba($black, 0.1);
			padding: 10px;

			&-number {
				padding: 8px;
				position: absolute;
				width: 56px;
				height: 100%;
				border-radius: 15px;
				left: -8px;
				display: flex;
				align-items: center;
				margin: 0px !important;
				justify-content: center;
				font-size: 18px;
				color: $white;
			}

			&-name {
				margin: 0;
				margin-left: 56px;
				font-weight: 600;
			}

			&-disabled {
				opacity: 0.5;
				cursor: default;
			}
		}
	}
}