@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.tbls-container {
  width: 100%;
  display: flex;
  flex-direction: column;

  .content-scroller {
    display: flex;
    overflow-y: auto;
    max-height: calc(100vh - 80px);
  }

  &-content {
    width: 100%;
    padding: 24px 40px;
  }

  &-item {
    padding: 16px 0px;
    margin-top: 16px;
    border-radius: 10px;
    background-color: $grayLight;
  }

  &-footer {
    padding: 32px 40px;
    margin-top: 32px;
  }
}

.tbls-item {
  padding: 8px;
  display: flex;
  margin: 16px;
  border-radius: 10px;
  align-items: center;
  background-color: $grayLight;

  &-col {
    margin: 0 4px;
    padding: 4px !important;
    background-color: $white;
    max-width: 45%;
    overflow: hidden;
  }

  &-answer {
    width: 100%;
    padding: 8px;
    border-radius: 10px;
    background-color: $grayLight;
    min-height: 40px;

    img{
      width:100%;
      height:100%;
    }

    &--faded {
      color: grey;
    }
  }
}