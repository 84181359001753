@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.subjectchoice-container {
	.onboarding-container-dynamic {
		padding: 0px;
	}

	&-content {
		top: 0px;
		left: 0px;
		z-index: 2;
		width: 100%;
		min-height: 100vh;
		display: flex;
		// overflow-y: auto;
		position: absolute;
		text-align: center;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		padding: calc(58px + 32px) 0px;
	}

	&-wrapper {
		margin: 0px auto;
		max-width: 500px;
	}

	&-title {
		font-size: 24px;
		font-weight: 600;
		max-width: 400px;
		margin: 0px auto;
		line-height: 28px;
		color: $blackDark;
		margin-bottom: 48px;
	}

	&-label {
		font-size: 12px;
		line-height: 16px;
		color: $blackDark;
		margin-bottom: 4px;
		font-weight: normal;
	}

	&-input {
		text-align: initial;
		margin-bottom: 44px;

		&:last-child {
			margin-bottom: 0px;
		}
	}

	&-field {
		width: 100%;
		border: none;
		padding: 8px 12px;
		background-color: transparent;
		border-bottom: 1px solid rgba(34, 34, 34, 0.2);
	}

	&-subtitle {
		margin-bottom: 60px;
		margin-top:20px;
		
		p {
			font-size: 20px;
		}
	}

	.primary-button {
		margin: 0px auto;
	}

	&-packages {
		width: 80%;
	}
}

.subjects {
	&-wrapper {
		width: 100%;
		margin-bottom: 24px;
	}

	&-title {
		display: block;
		font-size: 20px;
		font-weight: 900;
		color: $blackDark;
		line-height: 28px;
		margin-bottom: 32px;
	}

	&-icon {
		width: 90%;
		margin-bottom: 16px;
		object-fit: contain;
	}

	&-label {
		display: flex;
		padding: 26px;
		cursor: pointer;
		font-size: 20px;
		padding-top: 0px;
		padding-bottom: 20px;
		font-weight: 600;
		margin: 0px 20px;
		line-height: 26px;
		position: relative;
		border-radius: 20px;
		align-items: center;
		flex-direction: column;
		box-sizing: content-box;
		justify-content: center;
		background-color: $white;
		box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.065);

		&--selectedmaths {
			color: $white;
			background-color: $redDark;
		}

		&--selectedenglish {
			color: $white;
			background-color: $greenDark;
		}

		&--selectedscience {
			color: $white;
			background-color: $blueDark;
		}

		&--disabled {
			opacity: 0.6;
		}
	}

	&-field {
		top: 0px;
		left: 0px;
		width: 1px;
		opacity: 0;
		height: 1px;
		position: absolute;

		&:checked + .selections-label {
			border: 1px solid rgba(0, 111, 106, 1);
		}
	}
}

.package-container {
	margin-top: 70px;
}

.package-wrapper {
	position: relative;
	width: 96%;
	margin: 0 auto;
	margin-top: 45px;
	max-width: 500px;
	padding: 37px 16px;
	border-radius: 30px;
	background: linear-gradient(0deg, #fe638b, #ff9b6a);

	&-subjects {
		position: relative;
		height: 50px;
		margin-bottom: 50px;

		h5 {
			position: absolute;
			width: 100%;
			left: 50%;
			top: 6px;
			font-size: 22px;
			transform: translateX(-50%) perspective(2px) rotateX(1deg);
		}

		&::before {
			content: '';
			position: absolute;
			width: 80%;
			left: 50%;
			transform: translateX(-50%);
			height: 6px;
			border-radius: 30px;
			background-color: $white;
		}
	}

	&-price {
		h5 {
			font-size: 36px;
			color: $white;
			font-weight: 700;
			margin-bottom: 0;
		}
		.currency {
			font-size: 36px;
		}
	}

	&-tax {
		h5 {
			font-size: 20px;
			color: #7b3343;
			font-weight: 900;
		}
	}

	&-description {
		margin: 0 auto;
		display: inline-block;

		p {
			color: $white;
			font-size: 900;
			font-size: 22px;
			text-align: center;
		}
	}

	&-cta {
		box-shadow: none;
		display: block;
		color: $blackDark;
		background: $white;
		text-align: center;
		margin: 0 auto;
		margin-top: 30px;
		padding: 15px 75px;
		border-radius: 12px;
		box-shadow: 0px 8px 20px rgba($black, 0.3);
		border: none;
		font-size: 22px;
		position: relative;
		font-weight: 600;
		z-index: 200;
	}
}

.trapezoid {
	position: absolute;
	width: 50%;
	height: 100%;
	background: $white;
	transform: translate(-50%) perspective(2px) rotateX(-1deg);
	left: 50%;
	top: 12px;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}

.circle {
	position: absolute;
	height: 100px;
	width: 100px;
	background-color: rgba($white, 0.15);
	border-radius: 50%;

	&-lg {
		height: 280px;
		width: 280px;
		right: -40px;
		bottom: -40px;
	}

	&-md {
		height: 150px;
		width: 280px;
		right: 100px;
		bottom: -70px;
	}

	&-sm {
		height: 150px;
		width: 150px;
		left: -60px;
		top: 80px;
	}

	&-xs {
		height: 80px;
		width: 80px;
		right: 30px;
		bottom: 50px;
	}
}
