@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.newsletters {
  padding: 0 40px;
  padding-bottom: 50px;

  &-grid {
    display: grid;
    margin-top: 60px;
    grid-row-gap: 50px;
    grid-column-gap: 20px;
    grid-template-columns: repeat(4, 1fr);

    @include largeDisplay {
      grid-template-columns: repeat(5, 1fr);
    }
  }
}
