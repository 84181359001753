@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.input-text {
	width: 100%;
	border: none;
	display: flex;
	font-size: 16px;
	line-height: 28px;
	padding: 8px 16px;
	padding-top: 12px;
	align-items: center;
	border-radius: 10px;
	background: $grayMedium;

	&:focus {
		outline: none;
	}
}

.input-icon {
	position: absolute;
	right: 14px;
	top: 50%;
	transform: translateY(-50%);
}
