@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

.payment-gateway-modal {
  .select-payment-title {
    margin: 0px;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 32px;
  }
}

.gateway-container {
  width: 100%;

  &-options {
    margin: 0px;
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    justify-content: center;
  }

  &-description {
    .description-title {
      color: $black;
      font-size: 18px;
      font-weight: 600;
    }

    .description-subtitle {
      color: $grayDark;
      font-size: 16px;
      margin-top: -5px;
    }
  }
}

.gateway-item {
  padding: 4px;
  cursor: pointer;
  text-align: center;
  object-fit: contain;
  border: 2px solid transparent;

  img {
    border-radius: 4px;
  }

  &--selected {
    border-radius: 14px;
    border: 2px solid #76c1f3;
  }
}

.proceed-pay-btn {
  padding: 6px;
  min-width: 160px;
}
