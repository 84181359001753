// Regular Font Face
@font-face {
  font-family: 'Avenir LT Std';
  src: url('../fonts/AvenirLTStd-Book.eot');
  src: url('../fonts/AvenirLTStd-Book.eot?#iefix') format('embedded-opentype'),
    url('../fonts/AvenirLTStd-Book.woff2') format('woff2'),
    url('../fonts/AvenirLTStd-Book.woff') format('woff'),
    url('../fonts/AvenirLTStd-Book.ttf') format('truetype'),
    url('../fonts/AvenirLTStd-Book.svg#AvenirLTStd-Book') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// Regular-Italic Font Face
@font-face {
  font-family: 'Avenir LT Std';
  src: url('../fonts/AvenirLTStd-BookOblique.eot');
  src: url('../fonts/AvenirLTStd-BookOblique.eot?#iefix') format('embedded-opentype'),
    url('../fonts/AvenirLTStd-BookOblique.woff2') format('woff2'),
    url('../fonts/AvenirLTStd-BookOblique.woff') format('woff'),
    url('../fonts/AvenirLTStd-BookOblique.ttf') format('truetype'),
    url('../fonts/AvenirLTStd-BookOblique.svg#AvenirLTStd-BookOblique') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

// Medium Font Face
@font-face {
  font-family: 'Avenir LT Std';
  src: url('../fonts/AvenirLTStd-Medium.eot');
  src: url('../fonts/AvenirLTStd-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/AvenirLTStd-Medium.woff2') format('woff2'),
    url('../fonts/AvenirLTStd-Medium.woff') format('woff'),
    url('../fonts/AvenirLTStd-Medium.ttf') format('truetype'),
    url('../fonts/AvenirLTStd-Medium.svg#AvenirLTStd-Medium') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

// Bold Font Face
@font-face {
  font-family: 'Avenir LT Std';
  src: url('../fonts/AvenirLTStd-Heavy.eot');
  src: url('../fonts/AvenirLTStd-Heavy.eot?#iefix') format('embedded-opentype'),
    url('../fonts/AvenirLTStd-Heavy.woff2') format('woff2'),
    url('../fonts/AvenirLTStd-Heavy.woff') format('woff'),
    url('../fonts/AvenirLTStd-Heavy.ttf') format('truetype'),
    url('../fonts/AvenirLTStd-Heavy.svg#AvenirLTStd-Heavy') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

// Bolder Font Face
@font-face {
  font-family: 'Avenir LT Std';
  src: url('../fonts/AvenirLTStd-Black.eot');
  src: url('../fonts/AvenirLTStd-Black.eot?#iefix') format('embedded-opentype'),
    url('../fonts/AvenirLTStd-Black.woff2') format('woff2'),
    url('../fonts/AvenirLTStd-Black.woff') format('woff'),
    url('../fonts/AvenirLTStd-Black.ttf') format('truetype'),
    url('../fonts/AvenirLTStd-Black.svg#AvenirLTStd-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
