@import '../../../node_modules/bootstrap/scss/bootstrap.scss';

@import './variables';
@import './mixins';
@import './fonts';

@import './globals.scss';
@import './headers.scss';
@import './onboarding.scss';
@import './content.scss';
