@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

.transactions {
  width: 100%;
  height: auto;
  display: flex;
  padding: 40px;
  height: 90vh;
  overflow-y: auto;
  flex-direction: column;

  &-header {
    width: 100%;
    margin-bottom: 32px;

    &-title {
      color: $black;
      font-size: 20px;
      font-weight: 700;
    }
  }
}
