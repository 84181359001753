@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.paymentsuccess-container {
	min-height: 100vh;
	overflow-y: auto;
	.onboarding-container-dynamic {
		padding: 0px;
	}

	&-content {
		top: 0px;
		left: 0px;
		z-index: 2;
		width: 100%;
		min-height: 100vh;
		display: flex;
		overflow-y: auto;
		position: absolute;
		text-align: center;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		padding: calc(58px + 32px) 0px;
	}

	&-wrapper {
		margin: 0px auto;
		max-width: 500px;
	}

	&-title {
		font-size: 20px;
		font-weight: 700;
		max-width: 320px;
		margin: 0px auto;
		line-height: 28px;
		color: $blackDark;
		margin-bottom: 48px;
	}

	.primary-button {
		margin: 0px auto;
	}
}

.paymentsuccess-wrapper {
	width: 100%;
	padding: 16px;
	margin-bottom: 36px;
	flex-direction: column;

	&-icon {
		width: 100px;
		height: 100px;
		margin-left: 32px;
		align-self: center;
		margin-bottom: 16px;
	}

	&-title {
		font-size: 18px;
		font-weight: 600;
		line-height: 24px;
		color: $blackDark;
		margin-bottom: 16px;
	}

	&-subtitle {
		font-size: 16px;
		font-weight: 600;
		line-height: 24px;
		color: $blackLight;
		margin-bottom: 16px;
	}
}
