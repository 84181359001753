// Define Common Colors
$white: #ffffff;
$black: #000000;

// Define the Theme Colors

// $grayLight: #f7f7f7;
$grayLight: #f4f4f4;
$blackDark: #4a4a4a;
$blackLight: #5b6266;
$whiteLight: #fdfeff;
$grayDark : #484848;
$grayMedium:#dee2e6;

// Define the Variable Theme Colors
$blueLight: rgba(123, 201, 253, 0.4);
$redLight: rgba(237, 92, 92, 0.2);
$greenLight: rgba(0, 155, 48, 0.18);
$yellowLight: rgba(254, 227, 176, 0.65);

$redDark: #f37979;
$greenDark: #05c1a7;
$blueDark: #0390f2;

