@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.mcqm-container {
  display: flex;
  flex-direction: column;
  width:100%;

  .content-scroller {
    display: flex;
    overflow-y: auto;
    max-height: calc(100vh - 80px);
  }

  &-content {
    padding: 24px 40px;
    width:100%;
  }

  &-item {
    margin-top: 16px;
  }

  &-footer {
    padding: 32px 40px;
    margin-top:32px;
  }

  .mcqm-item {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    label {
      width: 100%;
      cursor: pointer;
      margin-left: 16px;
      margin-bottom: 0px;
      text-align: justify;

      img {
        max-width: 30%;
      }
    }

    .answer-checkbox {
      position: relative;
      &:after {
        width: 20px;
        height: 20px;
        content: '\00a0';
        visibility: visible;
        display: inline-block;
        border: 2px solid white;
        background-color: lightgray;
      }

      &:checked:after {
        content:'';
        background: url('../../../assets/images/checked.png') no-repeat center/cover;
        background-size: 10px;
        text-align: center;
        background-color:#b1e7c3;
        position: absolute;
      }

      &-wrong:after {
        color: white;
        content: '\2713';
        text-align: center;
        background-color: $redDark;
      }

      &-wrong:checked:after {
        content:'';
        background: url('../../../assets/images/checked-red.png') no-repeat center/cover;
        background-size: 10px;
        text-align: center;
        background-color:#f08080;
        position: absolute;
      }
    }
  }
}
