@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

.modal-footer {
  border-top: none !important;
  align-items: center !important;
  justify-content: center !important;
  margin-bottom: 32px;
  // height: 100px
}

.modal-body {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 40px 20px !important;
  margin-top:32px;

  h4 {
    font-size: 16px;
    font-weight: 600;
  }
}

.english-tag {
  display: flex;
  padding: 12px 24px;
  align-items: center;
  margin: 10px 0;
  background-color: #F3F4F6;
  width:45%;
  height:70px;
  min-width: 148px;
  border-radius: 10px;
  cursor: pointer;

  img{
    margin-bottom: 0;
    margin-right: 22px;
  }

  &-selected {
    border: 1px solid #46E688;
  }

  h5 {
    color: $black;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0px !important;
  }

}