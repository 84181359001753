@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.questionnaire-container {
	width: 100%;
	display: flex;
	height: auto;
	padding: 20px;
}

.questionhint {
	&-icon {
		cursor: pointer;
	}

	&-overlay {
		background-color: $white;
		padding: 12px;
		position: absolute;
		width: 300px;
		right: -16px;
		top: 72px;
		border-radius: 10px;
		box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.1);

		&::before,
		&::after {
			content: '';
			position: absolute;
			bottom: 100%;
			right: 19px;
			border: 11px solid transparent;
			border-bottom-color: #dddddd;
		}
		&:after {
			right: 20px;
			border: 10px solid transparent;
			border-bottom-color: $white;
		}
	}

	&-title {
		color: $black;
		text-align: center;
		font-size: 16px;
		font-weight: 600;
	}

	&-content {
		background-color: $grayLight;
		padding: 10px;
		border-radius: 10px;
		font-size: 14px;
		max-height: 300px;
		overflow-y: auto;
		word-break: break-all;

		&-image {
			max-width: 100%;
			cursor: pointer;
			max-height: 100px;
			margin-bottom: 8px;
		}
	}

	&-info {
		font-size: 12px;
		margin-bottom: 0;
		color: $blackLight;
		text-align: center;
	}
}
