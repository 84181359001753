@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.studykit-container {
  // background-color: $;

  .onboarding-container-dynamic {
    padding: 0px;
  }

  &-content {
    top: 0px;
    left: 0px;
    z-index: 2;
    width: 100%;
    // min-height: 100vh;
    display: flex;
    position: absolute;
    margin-top: 25px;
    // text-align: center;
    align-items: center;
    flex-direction: column;
    padding: 0 40px;
  }

  .primary-button {
    margin: 0px auto;
  }
}

.studykit-header {
  width: 100%;
  // display: flex;
  // align-items: center;

  &-title {
    color: $black;
    font-size: 28px;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 4px;
  }

  &-subtitle {
    color: $black;
    margin-top: 14px;
    font-size: 24px;
    font-weight: 600;
    display: block;
    margin-left: 4px;
    line-height: 24px;
    margin-bottom: 0px;
  }
}

.study-kit-title-container {
  align-items: center;
  margin-top: 34px;

  h5 {
    margin-bottom: 0 !important;
    color: $blueDark;
    font-weight: 700;
    margin-left: 22px;
    font-size: 28px;
    margin-top: 10px;
  }
}

.studykit-content {
  width: 100%;
  display: flex;
  // align-items: center;
  flex-direction: column;

  .subtitle {
    font-size: 18px;
    margin-top: 22px;
    text-align: center;
    width: 70%;
    line-height: 24px;
    display: block;
    margin: 0 auto;
    margin-top: 20px;
  }

  &-tabs {
    display: flex;
    flex-direction: row;
    margin-top: 40px;
  }

  .active-label {
    background-color: #0694f1;
    color: #fff;
  }

  &-tab-label {
    color: $black;
    padding: 16px;
    font-size: 18px;
    cursor: pointer;
    margin-right: 8px;
    background-color: $grayMedium;
    margin-bottom: 0px !important;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;

    &:hover {
      transform: translateY(-5px);
      transition: transform 0.4s ease-in-out;
    }

    &:last-child {
      margin-right: 15px;
    }
  }

  &-video {
    display: flex;
    // align-items: center;
    width: 100%;
    justify-content: flex-end;
  }

  &-walkthrough {
    display: flex;
    // align-items: center;
    justify-content: flex-end;
  }

  .studykit-body {
    width: 100%;
    height: 508px;
    background-color: $grayMedium;
    border-radius: 20px;
    margin-bottom: 40px;
    border-top-right-radius: 0;

    &-left {
      height: 100%;
      max-width: 327px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      border-right: 1px solid $white;
      padding: 0;

      & > div {
        flex: 1;
      }
    }

    &-right {
      width: 100%;
      height: 100%;
      overflow-y: auto;

      .content {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 18px;
        padding: 24px 0;
      }
    }
  }
}

.studykit-msg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 22px;
  font-weight: 600;
}

.studykit-resource {
  width: 100%;
  display: flex;
  padding: 16px;
  cursor: pointer;
  align-items: center;
  padding-left: 15%;
  border-bottom: 1px solid $white;

  .chevron {
    position: absolute;
    right: 30px;

    img {
      height: 15px;
      width: 15px;
    }
  }

  .resource-info {
    margin-top: 8px;
    margin-left: 45px;
  }

  .resource-title {
    color: $black;
    font-size: 22px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 0px;
    margin-bottom: 0 !important;
  }

  .resource-subtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: $blackLight;
    margin-top: 8px;
    font-weight: 600;
    margin-bottom: 0px;
  }

  &:first-child {
    border-top-left-radius: 15px;
  }

  &:last-child {
    border-bottom-left-radius: 15px;
  }
}

.studykit-resource-selected {
  // background: linear-gradient(to bottom, rgba($white, 0.2), rgba(#0694f1, 0.2));
  background: #0694f1;

  .resource-title {
    color: #0593f2;
    font-weight: 700;
  }

  .resource-subtitle {
    font-weight: 400;
    line-height: 20px;
    color: #47aef4;
  }

  &:first-child {
    border-top-left-radius: 15px;
  }

  &:last-child {
    border-bottom-left-radius: 15px;
  }

  &--selected {
    background-color: #e3eef7;
  }
}

.resource-item {
  max-height: 250px;
  overflow: hidden;
  display: flex;
  cursor: pointer;
  align-items: center;
  border-radius: 10px;
  flex-direction: column;
  padding: 0px !important;
  background-color: $white;
  box-shadow: 0px 10px 40px #00345817;

  &-video {
    align-items: flex-start;

    .link {
      color: #8892a6;
      margin-top: 30px;
      display: flex;
      align-items: center;
      font-size: 16px;
      color: $blueDark;

      img {
        margin-left: 6px;
        width: 12px;
        height: 12px;
        margin-top: 0.8px;
      }

      &:hover {
        img {
          margin-left: 12px;
          transition: all 0.5s;
        }
      }
    }
  }

  .res-info {
    padding: 14px;
  }

  &-icon {
    width: 100%;
    height: 100px;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &-title {
    color: $black;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 0px !important;
  }

  &-description {
    font-size: 12px;
    font-weight: 400;
    margin-top: 8px;
    line-height: 16px;
    color: $blackLight;
    margin-bottom: 0px;
  }
}
