@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.mtf-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 24px;

  .content-scroller {
    display: flex;
    overflow-y: auto;
    max-height: calc(100vh - 80px);
  }

  &-content {
    width: 100%;
    padding: 24px 16px;
  }

  &-item {
    padding: 16px 0px;
    margin-top: 16px;
    border-radius: 10px;
    background-color: $grayLight;
  }

  &-footer {
    padding: 32px 16px;
    margin-top: 32px;
  }
}

.mtf-item {
  padding: 8px;
  display: flex;
  margin: 16px;
  border-radius: 10px;
  align-items: center;
  background-color: $grayLight;

  &-col {
    margin: 0 12px;
    padding: 8px;
    max-width:210px;
    background-color: $white;
  }

  &-answer {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 10px;
    background-color: $grayLight;

    &--faded {
      color: grey;
    }

    img {
      max-width: 180px;
      max-height: 100px;
      object-fit: contain;
      border-radius: 12px;
    }
  }
}