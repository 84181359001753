@import '../../../../assets/styles/mixins';
@import '../../../../assets/styles/variables';


.parent-onboarding-mobile {
  position: relative;
  background-color: red;

  .mobileentry-container {
    &-content {
      top: 0px;
      z-index: 2;
      display: flex;
      min-height: 100vh;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      text-align: center;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      width: calc(100% - 600px);

      @include laptopDisplay {
        // left: 35%;
        // width: 65%;
      }
    }

    &-title {
      font-size: 18px;
      font-weight: 900;
      line-height: 38px;
      color: $blackDark;
      margin-bottom: 24px;
      text-transform: none;
    }

    &-description {
      font-size: 16px;
      line-height: 22px;
      color: $blackLight;
      font-weight: normal;
      margin-bottom: 72px;
    }

    &-label {
      font-size: 18px;
      opacity: 0.8;
      font-weight: bold;
      line-height: 28px;
      color: $blackDark;
      margin-bottom: 32px;
    }

    &-input {
      margin-top: 80px;
      margin-bottom: 0;
      border-bottom: 1px solid rgba(34, 34, 34, 0.2);
    }

    .use-same-num-container {
      margin-bottom: 60px;
      margin-top:20px;
      align-items: center;
      display: flex;

      label{
        margin:0;
        margin-left:8px;
      }
    }

    &-field {
      border: none;
      margin: 4px 0px;
      padding: 8px 12px;
      background-color: transparent;

      &:first-child {
        max-width: 65px;
        text-align: center;
        border-right: 1px solid rgba(34, 34, 34, 0.2);
      }
    }
  }
}