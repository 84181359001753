@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.mobileentry-container {
  &-content {
    top: 0px;
    z-index: 2;
    left: 600px;
    display: flex;
    min-height: 100%;
    position: absolute;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: calc(100% - 600px);

    @include laptopDisplay {
      left: 35%;
      width: 65%;
    }
  }

  &-title {
    font-size: 28px;
    font-weight: 900;
    line-height: 38px;
    color: $blackDark;
    margin-bottom: 24px;
    text-transform: uppercase;
  }

  &-description {
    font-size: 16px;
    line-height: 22px;
    color: $blackLight;
    font-weight: normal;
    margin-bottom: 72px;
  }

  &-label {
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
    color: $blackDark;
    margin-bottom: 32px;
  }

  &-input {
    margin-bottom: 80px;
    border-bottom: 1px solid rgba(34, 34, 34, 0.2);
  }

  &-field {
    border: none;
    margin: 4px 0px;
    padding: 8px 12px;
    background-color: transparent;

    &:first-child {
      max-width: 65px;
      text-align: center;
      border-right: 1px solid rgba(34, 34, 34, 0.2);
    }
  }
}

.swal2-styled.swal2-confirm {
  background: linear-gradient(148deg, #58f2e9 -48px, #0390f2 100%) !important
}

.swal2-styled.swal2-confirm:focus {
  box-shadow: none !important;
}