@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

.modal-content {
  padding: 16px;
}
.modal-body {
  margin: 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 0px 4px !important;
}

.modal-header {
  border-bottom: none !important;

  .modal-title {
    font-size: 24px;
    font-weight: 600;
  }
}
