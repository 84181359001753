@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.suggestions-container {
  display: flex;
  margin-top: 32px;
  flex-direction: column;

  &-header {
    margin-bottom: 16px;
  }

  &-content {
    .suggestion-item {
      padding: 8px;
      margin-bottom: 8px;
      border-radius: 10px;
      background-color: $grayMedium;
    }

    .suggestion-item-col {
      // padding: 8px;
      padding: 10px 6px 10px 12px;
      margin: 16px 8px;
      margin-bottom: 8px;
      border-radius: 10px;
      background-color: $grayMedium;
      // text-align: center;
      font-size: 18px;
      font-weight: 600;
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;

      img {
        max-width: 180px;
        max-height: 100px;
      }
    }
  }
}


.suggestion-label {
  margin-bottom: 0;
  padding-right: 8px;
  font-size: 14px;
}

.suggestion-bars {
  // position: absolute;
  // right: 22px;
  // top: 50%;
  // transform: translateY(-50%);

  .bar {
    // position: absolute;
    height: 1.4px;
    width: 10px;
    background-color: $blackLight;

    &:last-child {
      margin-top: 3px;
    }
  }

}