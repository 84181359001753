@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.cart {
	padding: 0 40px;
	padding-bottom: 50px;
	margin-top: 16px;

	&-content {
		width: 100%;
		display: flex;
		margin-top: 40px;
		flex-direction: column;

		&-header {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 8px;
			padding-bottom: 8px;
			align-items: center;
			border-bottom: 1px solid rgba(#8892a6, 0.4);

			h5 {
				color: #212426;
				font-size: 14px;
				margin-bottom: 0px !important;
			}
		}

		&-item {
			display: flex;
			flex-wrap: wrap;
			margin-top: 32px;
		}

		&-separator {
			margin: 40px 0px;
			border-bottom: 1px dashed rgba(#8892a6, 0.3);
		}

		&-total {
			display: flex;
			// flex-wrap: wrap;

			h5 {
				color: $black;
				font-size: 20px;
				font-weight: 700;
				margin-bottom: 0px !important;

				&:nth-child(1) {
					text-align: end;
				}
			}
		}

		.cart-content-remove-icon-container {
			display: flex;
			cursor: pointer;
			margin-top: 10px;
			align-items: center;

			h5 {
				margin-left: 4px;
				font-weight: 600;
				color: $grayDark;
				margin-top: 1px;
			}
		}

		&-cta {
			display: flex;
			margin-top: 100px;
			align-items: center;
			justify-content: center;
		}
	}

	.empty-state {
		.img {
			max-width: 130px;
		}
	}
}

.productprice {
	color: $black;
	font-size: 20px;
	font-weight: 700;
}

.productdata {
	display: flex;

	&-left {
		display: flex;
		margin-right: 12px;
		flex-direction: column;

		.productdata-img-container {
			height: 110px;
			width: 180px;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				margin-bottom: 12px;
				border-radius: 10px;
			}
		}

		h5 {
			opacity: 0.6;
			font-size: 16px;
			cursor: pointer;
			color: #7e7e7e;
			margin-bottom: 0px !important;
		}
	}

	&-right {
		width: 100%;
		display: flex;
		flex-direction: column;

		&-title {
			color: $black;
			font-size: 18px;
			font-weight: 700;
			margin-bottom: 12px !important;
		}

		&-subtitle {
			color: $black;
			font-size: 16px;
			font-weight: 500;
			width: 80%;
			margin-bottom: 0px !important;
		}
	}
}
