@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.lesson-progress {
	&-container {
		width: 100%;
		height: auto;
		display: flex;
		padding: 40px;
		padding-top: 20px;
		// overflow-y: auto;
		min-height: 70vh;
		flex-direction: column;
		position: relative;

		.lessons-grid {
			display: grid;
			grid-gap: 10px;
			margin-top: 40px;
			grid-template-columns: repeat(3, 1fr);

			.empty-state {
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);

				.img {
					max-width: 90px;
				}
			}
		}
	}

	&-header-container {
		display: flex;
		justify-content: space-between;
		margin-bottom: 46px;
	}

	&-header {
		width: 100%;
		display: flex;
		margin-bottom: 32px;
		align-items: center;
		flex-basis: 60%;

		&-title {
			color: $black;
			margin: 0;
			line-height: 28px;
			margin-left: 22px;
			font-weight: 700;
			font-size: 30px;
		}

		&-icon {
			width: 60px;
			height: 60px;
			border-radius: 12px;
		}
	}

	&-body {
		.row {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		&-header {
			padding-bottom: 12px;

			h5 {
				flex-basis: 60%;
			}

			.input-container {
				flex-basis: 40%;
			}
		}

		.lesson {
			&-heading-container {
				display: flex;
				align-items: center;
				flex: 1;
			}

			&-icon {}

			&-title {
				font-size: 22px;
				flex: 1;
				margin-bottom: 0 !important;
				margin-left: 22px;
				color: $black;
				font-weight: 600;
			}
		}

		.items-container {
			margin-top: 30px;
			// min-height: 50vh;

			.empty-state {
				transform: translate(-50%, -50%);

				.img {
					max-width: 120px;
				}
			}
		}

		.dividing-line {
			position: absolute;
			height: 100%;
			width: 2px;
			top: 0;
			left: 50%;
			background-color: rgba($black, 0.1);
			transform: translateX(-50%);
		}

		.lesson-item {
			margin: 8px;
			// padding: 8px;
			display: flex;
			position: relative;
			cursor: pointer;
			border: 1px solid $grayLight;
			border-radius: 10px;
			align-items: center;
			box-shadow: 2px 2px 10px 2px rgba($black, 0.1);
			padding: 10px;

			&-number {
				padding: 8px;
				position: absolute;
				width: 56px;
				height: 100%;
				border-radius: 15px;
				left: -8px;
				display: flex;
				align-items: center;
				margin: 0px !important;
				justify-content: center;
				font-size: 18px;
				color: $white;
			}

			&-name {
				margin: 0px;
				margin-left: 56px;
				font-weight: 600;
			}

			&-disabled {
				opacity: 0.5;
				cursor: default;
			}
		}
	}
}