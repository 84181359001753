@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.laqwl-container {
  width: 100%;
  display: flex;
  flex-direction: column;

  .content-scroller {
    display: flex;
    overflow-y: auto;
    max-height: calc(100vh - 80px);
  }

  &-content {
    width: 100%;
    padding: 24px 40px;
  }

  &-item {
    margin-top: 16px;
  }

  &-footer {
    padding: 32px 40px;
    margin-top: 32px;
  }
}

.keywords-container {
  display: flex;
  margin-top: 16px;
  flex-direction: column;

  &-header {
    margin-bottom: 8px;

    &-subtext {
      color: grey;
      margin-bottom: 0;
    }
  }
}
