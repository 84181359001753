@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.model-projects {
  padding: 0 40px;
  padding-bottom: 50px;


  .model-container {
    margin-top: 30px;
  }

  .icon {
    width: 50px;
  }

  &-grid {
    display: grid;
    margin-top: 40px;
    grid-row-gap: 50px;
    margin-bottom: 60px;
    grid-column-gap: 20px;
    grid-template-columns: repeat(4, 1fr);

    @include largeDisplay {
      grid-template-columns: repeat(5, 1fr);
    }
  }
}