.onboarding-container {
  background-color: $grayLight;

  &-static {
    top: 0px;
    left: 0px;
    width: 100%;
    display: flex;
    height: 100vh;
    padding: 32px;
    max-width: 600px;
    padding-top: 90px;
    position: absolute;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: linear-gradient(147deg, #58f2e9 0%, #7ecbff 100%);

    @include laptopDisplay {
      max-width: 35%;
    }
  }

  &-definer {
    margin: 0px;
    font-size: 20px;
    color: #03045e;
    line-height: 28px;
    text-align: center;
  }

  &-dynamic {
    min-height: 100%;
    position: relative;
    padding-left: 600px;

    @include laptopDisplay {
      padding-left: 35%;
    }
  }

  .decoration-layer {
    width: 100%;
    min-height: 100vh;
    position: relative;

    &-one {
      left: 8px;
      top: 144px;
      width: 210px;
      height: 228px;
      position: absolute;
      background: url('../../assets/images/top-left.svg') center no-repeat;
      background-size: contain;
    }

    &-two {
      top: 16px;
      opacity: 0.6;
      width: 150px;
      height: 161px;
      position: absolute;
      left: calc(50% - 75px);
      background: url('../../assets/images/top-center.svg') center no-repeat;
      background-size: contain;
    }

    &-three {
      top: 144px;
      right: 8px;
      opacity: 0.6;
      width: 149px;
      height: 177px;
      position: absolute;
      background: url('../../assets/images/top-right.svg') center no-repeat;
      background-size: contain;
    }

    &-four {
      right: 36px;
      bottom: 48px;
      width: 168px;
      opacity: 0.6;
      height: 286px;
      position: absolute;
      background: url('../../assets/images/bottom-right.svg') center no-repeat;
      background-size: contain;
    }

    &-five {
      bottom: 0px;
      width: 312px;
      opacity: 0.6;
      height: 162px;
      position: absolute;
      left: calc(50% - 156px);
      background: url('../../assets/images/bottom-center.svg') top no-repeat;
      background-size: cover;
    }

    &-six {
      left: 32px;
      width: 256px;
      opacity: 0.6;
      bottom: 38px;
      height: 248px;
      position: absolute;
      background: url('../../assets/images/bottom-left.svg') center no-repeat;
      background-size: contain;
    }
  }
}
