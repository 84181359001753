@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.row-pay-option {
	display: flex;
	align-items: center;
}

.payment-container {
	.onboarding-container-dynamic {
		padding: 0px;
	}

	.payment-option-container {
	}

	&-content {
		top: 0px;
		left: 0px;
		z-index: 2;
		width: 100%;
		min-height: 100vh;
		display: flex;
		overflow-y: auto;
		position: absolute;
		text-align: center;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		padding: calc(58px + 32px) 0px;
	}

	&-wrapper {
		width: 60%;
		margin: 0px auto;
	}

	&-title {
		font-size: 20px;
		font-weight: 600;
		max-width: 320px;
		margin: 0px auto;
		line-height: 28px;
		color: $blackDark;
		margin-bottom: 36px;
	}

	&-label {
		font-size: 12px;
		line-height: 16px;
		color: $blackDark;
		margin-bottom: 4px;
		font-weight: normal;
	}

	&-input {
		text-align: initial;
		margin-bottom: 44px;

		&:last-child {
			margin-bottom: 0px;
		}
	}

	.primary-button {
		margin: 0px auto;
	}
}

.accordion-wrapper {
	max-width: 600px;
	margin:0 auto;
	width: 100%;
	padding: 16px;
	flex-direction: column;

	&-item {
		width: 100%;
		margin-top: 16px;
		border-radius: 5px;
		align-items: center;
		background-color: $white;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 16px 30px;
		cursor: pointer;
		box-shadow: 0px 10px 30px #0E18230A;

		.accordion-title {
			// padding: 8px;
			font-size: 16px;
			font-weight: 600;
			line-height: 20px;
			color: $blackDark;
			margin-bottom: 0px !important;
			margin-left: 20px;
		}
	}

	.chevron {
		img {
			height: 15px;
			width: 15px;
		}

		&-active {
			transform: rotate(90deg);
			transition: transform 0.3s;
		}
	}

	&-content {
		width: 100%;
		display: flex;
		align-items: center;
		flex-direction: column;
		background-color: $white;
		border-top: 1px solid $black;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		padding: 15px 0 36px 0;

		.card-content {
			width: 46%;

			.card-pay-btn {
				margin-top: 24px;
			}
		}

		.accordion-row {
			display: flex;
			// padding: 18px 48px;
			align-items: center;
			justify-content: space-between;
			margin-top: 12px;

			&-input {
				margin-top: 14px;
				padding: 0px 8px;
				text-align: initial;
			}

			&:last-child {
				margin-bottom: 0px;
			}

			&-label {
				font-size: 12px;
				line-height: 16px;
				color: $blackDark;
				margin-bottom: 4px;
				font-weight: normal;
			}

			&-field {
				width: 100%;
				border: none;
				padding: 0px 8px;
				background-color: transparent;
				border-bottom: 1px solid rgba(34, 34, 34, 0.2);
			}

			.primary-button {
				padding: 6px 0;
				margin-top: 18px;
			}
		}
	}
}
