@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.question-header {
	&-image {
		max-height: 320px;
		align-self: center;
		border-radius: 10px;
		margin-bottom: 24px;
		object-fit: cover;
		cursor: pointer;
	}

	&-text {
		color: $black;
		font-size: 18px;
		font-weight: 600;
		line-height: 22px;
		margin-bottom: 16px;
		text-align: justify;

		@include laptopDisplay{
			max-width: 85%;
		}
	}

	&-link {
		font-size: 18px;
		font-weight: 600;
		color: $blueDark;
		line-height: 22px;
		margin-bottom: 16px;
		text-align: justify;
	}

	&-video {
		width: 100%; 
		margin-bottom: 16px;

		iframe {
			min-width: 320px;
			max-width: 75%;
			max-height: 480px;
		}
	}

	&-audio {
		width: 100%; 
		margin-bottom: 16px;

		audio::-webkit-media-controls-panel {
			background-color: #dee2e6;
		}
		
		audio {
			width: 75%;
		}
	}
}

.video-playback {
	min-width: 320px;
	max-width: 75%;
	max-height: 480px;
	background-color: #000;
}