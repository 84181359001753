@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.completeprofile-container {
  &-content {
    top: 0px;
    z-index: 2;
    left: 600px;
    height: 100vh;
    overflow-y: auto;
    position: absolute;
    text-align: center;
    width: calc(100% - 600px);
    padding: calc(58px + 32px) 0px;

    @include laptopDisplay {
      left: 35%;
      width: 65%;
    }
  }

  &-wrapper {
    margin: 0px auto;
    max-width: 500px;
  }

  &-title {
    font-size: 22px;
    font-weight: 600;
    max-width: 350px;
    margin: 0px auto;
    margin-top: 20px;
    line-height: 28px;
    color: $blackDark;
    margin-bottom: 48px;
  }

  &-fields {
    max-width: 380px;
    margin: 0px auto;
    margin-bottom: 80px;
  }

  &-label {
    font-size: 16px;
    line-height: 16px;
    color: $blackDark;
    margin-bottom: 4px;
    font-weight: normal;
  }

  &-input {
    text-align: initial;
    margin-bottom: 44px;
    margin-top: 12px;

    &:last-child {
      margin-bottom: 0px;
    }
  }

  &-field {
    width: 100%;
    border: none;
    padding: 8px 12px;
    background-color: transparent;
    border-bottom: 1px solid rgba(34, 34, 34, 0.2);
  }

  .primary-button {
    margin: 0px auto;
  }
}

.selections {
  &-wrapper {
    width: 100%;
    margin-bottom: 80px;
  }

  &-title {
    display: block;
    font-size: 20px;
    font-weight: 600;
    color: $blackDark;
    line-height: 28px;
    margin-bottom: 32px;
  }

  &-label {
    width: 68px;
    height: 68px;
    display: flex;
    padding: 14px;
    cursor: pointer;
    font-size: 24px;
    font-weight: 900;
    margin: 0px 16px;
    line-height: 30px;
    padding-top: 18px;
    position: relative;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    border: 2px solid transparent;
    background-color: rgba(64, 64, 64, 0.2);

    &--five {
      color: #006eac;
      background-color: $blueLight;
    }

    &--six {
      color: #bc4a00;
      background-color: $redLight;
    }

    &--seven {
      color: #008128;
      background-color: $greenLight;
    }

    &--eight {
      color: #775300;
      background-color: $yellowLight;
    }

    &--selected {
      border: 2px solid $blueDark;
    }
  }

  &-field {
    top: 0px;
    left: 0px;
    width: 1px;
    font-size: 16px;
    opacity: 0;
    height: 1px;
    position: absolute;

    &:checked + .selections-label {
      border: 1px solid rgba(0, 111, 106, 1);
    }
  }
}
