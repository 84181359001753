@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

.lesson-progress {
	display: flex;
	background-color: $white;
	box-shadow: 0px 10px 40px #00345817;
	border-radius: 14px;
	padding: 16px;

	.lesson {
		&-num-container {
			flex: 0.1;
			margin-left:-6px;
		}

		&-num {
			font-size: 30px;
			font-weight: 700;
			color: #c4dae4;
		}

		&-info {
			flex: 0.9;
			margin-left: 10px;
		}

		&-name {
			font-weight: 700;
			font-size: 18px;
		}

		&-type {
			font-size: 14px;
			font-weight: 600;
			opacity: 0.7;
		}
	}

	.check-icon {
		width: 26px;
	}

	.progress-row {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 12px;
	}

	&-bar {
		background-color: #e5e8ec;
		height: 7px;
		border-radius: 5px;
		position: relative;
		margin-top: 14px;

		.progress-value {
			position: absolute;
			height: 100%;
			background-color: $blueDark;
			border-radius: 12px;
		}
	}

	&-count {
		display: flex;
		flex-direction: column;

		align-items: center;
		margin-top: 14px;

		.count {
			display: flex;
		}

		h3 {
			margin: 0;
			font-size: 20px;
			font-weight: 900;
		}

		.numerator {
			color: $blueDark;

			&:after {
				content: '/';
				color: $blackDark;
				margin: 0 4px;
				font-weight: 200;
			}
		}

		.denominator {
			color: rgba($blueDark, 0.7);
		}

		p {
			margin-bottom: 0;
			margin-left: 12px;
		}
	}
}
