@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.global-breadcrumb {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  background-color: $grayLight;
  justify-content: space-between;

  &-left,
  &-right {
    flex: 1;
    display: flex;
    padding: 0px 40px;
    align-items: center;
  }

  &-left,
  &-link,
  &-text {
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
    color: $blackLight;
  }

  &-right {
    justify-content: flex-end;
  }

  &-link {
    max-width: 50%;
    margin: 0px 8px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:active,
    &:hover,
    &:focus {
      color: $blueDark;
      text-decoration: none;
    }

    &:first-child {
      margin-left: 0px;
    }

    &:last-child {
      margin-right: 0px;
    }
  }

  &-text {
    max-width: 50%;
    margin: 0px 8px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:first-child {
      margin-left: 0px;
    }

    &:last-child {
      margin-right: 0px;
    }
  }

  &-option {
    border: 0px;
    width: 48px;
    height: 48px;
    padding: 0px;
    display: flex;
    outline: none;
    position: relative;
    align-items: center;
    justify-content: center;
    background-color: transparent;

    &:hover,
    &:active,
    &:focus {
      outline: none;
    }
  }

  .cart-count {
    top: -2px;
    right: -4px;
    width: 22px;
    height: 22px;
    display: flex;
    color: $white;
    font-size: 14px;
    padding-top: 2px;
    font-weight: bold;
    line-height: 20px;
    position: absolute;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: #46e579;
  }

  .hamburger-icon {
    width: 24px;

    &-line {
      height: 2px;
      margin-bottom: 6px;
      background-color: #212426;

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  .hamburger-menu {
    margin-left: 24px;
    position: relative;
    display: inline-block;

    &-items {
      z-index: 999;
      display: flex;
      margin-top: 0px;
      position: absolute;
      right: -5px;
      flex-direction: column;
      background-color: white;
      padding: 10px 24px;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      border-radius: 8px;

      &::before,
      &::after {
        content: '';
        position: absolute;
        bottom: 100%;
        right: 19px;
        border: 11px solid transparent;
        border-bottom-color: #dddddd;
      }
      &:after{
        right: 20px;
        border: 10px solid transparent;
        border-bottom-color: $white;
      }

      a {
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;
        padding:8px 0;
        color: $blackLight;

        &:active,
        &:hover,
        &:focus {
          color: $blueDark;
          text-decoration: none;
        }
      }
    }
  }
}