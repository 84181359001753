@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.advert-item {
	height: 231px;
	position: relative;
	width: 100%;
	flex-basis: 25%;
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		width: 100%;
		height: 43px;
		background: transparent linear-gradient(180deg, #00000000 0%, #000000 100%) 0% 0% no-repeat padding-box;
	}

	.label {
		text-align: left;
		letter-spacing: 0px;
		color: $white;
		opacity: 0.91;
		position: absolute;
		bottom: -5px;
		left: 10px;
		z-index: 80;
	}
}
