.content-container {
  padding-left: 260px;
  // background-color: $white;

  &-dynamic {
    min-height: 100vh;
    position: relative;
  }
}

.content-options {
  top: 80px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  display: flex;
  position: fixed;
  max-width: 200px;
  padding: 72px 32px;
  align-items: center;
  flex-direction: column;
  background-color: $grayLight;
  justify-content: flex-start;
  border-left: 1px solid rgba(0, 0, 0, 0.065);

  .hint {
    margin-bottom: 56px;

    &-title {
      font-size: 14px;
      line-height: 20px;
      color: $blackDark;
      margin-bottom: 8px;
    }

    &-icon {
      cursor: pointer;
    }
  }

  .progress-track {
    &-title {
      font-size: 14px;
      line-height: 20px;
      color: $blackDark;
      font-weight: 600;
      margin-bottom: 8px;
    }

    &-bar {
      width: 50px;
      height: 200px;
      margin: 0px auto;
      border-radius: 10px;
      margin-bottom: 16px;
      background: linear-gradient(
        148deg,
        rgba(88, 242, 233, 1) -48px,
        rgba(3, 144, 242, 1) 100%
      );
    }

    &-filler {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      background-color: $grayMedium;
    }

    &-count {
      font-size: 20px;
      font-weight: 600;
      line-height: 26px;
      color: $blueLight;
      text-align: center;
    }

    &-completed {
      color: #2fc3ed;
    }

    &-remaining {
      color: $blueDark;
    }
  }
}
