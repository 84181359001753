@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.lesson-container {
  width: 100%;
  display: flex;
  height: auto;
  padding: 20px;
}

.modal-footer {
  border-top: none !important;
  align-items: center !important;
  justify-content: center !important;
  margin-top: 30px;
}

.modal-content{
  padding:30px !important;
}

.modal-body {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 28px 20px !important;

  h4 {
    font-size: 16px;
    font-weight: 800;
    margin-top:10px;
  }

  h5 {
    color: $blackLight;
    font-size: 18px;
    text-align: center;
    text-align: justify;
  }
}
