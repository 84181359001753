* {
	outline: none !important;

	&:focus,
	&:active,
	&:hover {
		outline: none;
	}
}

html,
body {
	width: 100%;
	height: 100%;
	overflow-y: auto !important;
}

body {
	background-color: $grayLight;
	font-family: 'Avenir LT Std', sans-serif !important;
}

#root {
	min-height: 100%;
}

.global-container {
	min-height: 100vh;
	position: relative;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

::selection {
	color: $blueDark;
	background-color: $white;
}

.toast-progress {
	background: linear-gradient(148deg, #58f2e9 -48px, #0390f2 100%) !important;
}

.relative {
	position: relative;
}

.modal-footer {
	.primary-button {
		padding-top: 8px;
		padding-bottom: 8px;
		min-width: 176px;
	}
}

.question-modal-footer {
	position: absolute;
	bottom: 40px;
}
