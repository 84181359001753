@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.videodetails {
	width: 100%;
	height: auto;
	display: flex;
	padding: 40px;
	min-height: 100vh;
	// overflow-y: auto;
	flex-direction: column;

	&-header {
		width: 100%;
		margin-bottom: 12px;

		&-title {
			color: $black;
			font-size: 20px;
			font-weight: 700;
		}
	}

	&-content {
		width: 100%;
		max-height: 480px;
		border-radius: 20px;

		&-thumbnail {
			width: 100%;
			height: 100%;
			position: relative;

			img {
				width: 100%;
				max-height: 480px;
			}
		}
	}
}

.thumbnail-overlay {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 2;
	width: 100%;
	display: flex;
	position: absolute;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.5);

	.play-icon {
		width: 70px;
		cursor: pointer;

		&:hover {
			transform: scale(1.1);
			transition: transform 0.5s;
		}
	}

	&-content {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
	}

	&-title {
		color: $white;
		font-size: 24px;
		font-weight: 700;
	}

	&-subtitle {
		color: $white;
		font-size: 18px;
		// font-weight: 600;
		margin-top: 48px;
	}

	&-date {
		color: $white;
		font-size: 24px;
		font-weight: 600;
		margin-top: 12px;
	}
}

.player-video {
	width: 100%;
	height: 480px;
	background-color: #000;
}
