@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.activity-content {
  width: 100%;
  display: flex;
  padding: 0px 40px;
  flex-direction: column;

  &-title {
    color: $black;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 24px;
  }

  &-description {
    color: $black;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 4px;
  }

  .activity-frame {
    width: 100%;
    display: flex;

    &-embeded {
      width: 100%;

      iframe {
        width: 100%;
        height: 80vh;
        border-radius: 15px;
      }
    }

    &-url {
      width: 100%;
      height: 480px;
    }
  }
}