@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.lq-container {
	display: flex;
	flex-direction: column;
	width: 100%;

	.content-scroller {
		display: flex;
		overflow-y: auto;
		max-height: calc(100vh - 80px);
	}

	&-content {
		padding: 24px 40px;
		width: 100%;
	}

	&-item {
		margin-top: 16px;
	}

	&-footer {
		padding: 32px 40px;
    	margin-top: 32px;
	}

	.lq-item {
		padding: 8px;
		display: flex;
		margin: 16px;
		border-radius: 10px;
		align-items: center;
		background-color: $grayMedium;

		&-label {
			padding: 8px;
			color: $black;
			border-radius: 5px;
			margin-right: 12px;
			background-color: $grayLight;
		}

		&-answer {
			width: 100%;
			padding: 8px;
			padding-left: 0;

			&--faded {
				color: grey;
			}
		}
	}
}
