.section-title {
	display: flex;
	align-items: center;
	.icon {
		margin-right: 22px;
	}

	.title {
		margin: 0;
		font-size: 22px;
		font-weight: bold;
	}
}
