@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.mobile-overlay {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 9999;
	background-color: $white;
	padding: 24px;
	padding-top: 42px;

	.content {
		text-align: center;
	}

	.logo {
		text-align: center;
		max-height: 100px;
		width: 120px;
	}

	.message {
		max-width: 80%;
		margin: 0 auto;
		margin-top: 20vh;
		font-size: 18px;
	}

	.app-links {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 80px;

		img {
			width: 100%;
			max-width: 180px;
			height: 100%;
			border-radius: 8px;
			cursor: pointer;

			&:last-child {
				margin-top: 16px;
			}
		}
	}

	@include tabletDisplay {
		display: block;
	}
}
