@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.percent {
	position: absolute;
	top: 55%;
	left: 50%;
	width: 100%;
	transform: translate(-50%, -50%);
	text-align: center;

	.num {
		display: inline-block;
		// color: #0593F2;
		color: $blackLight;
		font-weight: 800;
		font-size: 24px;
		margin-bottom: 0;

		&:after {
			content: '/';
			margin: 0 6px;
		}
	}

	.den {
		display: inline-block;
		// color: #31C5ED;
		color: $blackDark;
		font-weight: 800;
		font-size: 24px;
		margin-bottom: 0;
	}

	.lessons-text {
		font-size: 14px;
		font-weight: 600;
		margin-bottom: 0.8rem;
	}
}
