@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.support {
  padding: 0 40px;
  padding-bottom: 50px;

  &-container {
    width: 75%;
    margin: 32px 0px;

    &-field {
      margin: 24px 0px;
    }

    &-attachments {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 32px;
      justify-content: space-between;
    }
  }

  .support-files {
    border: none;
    cursor: pointer;
    font-size: 20px;
    background: none;
    color: $blueDark;
    font-weight: 500;

    &-input {
      width: 1px;
      opacity: 0;
      height: 1px;
      top: -9999px;
      left: -9999px;
      position: absolute;
    }
  }

  .attachment {
    display: flex;
    max-width: 45%;
    border-radius: 5px;
    align-items: center;
    margin-bottom: 12px;
    padding: 4px 6px !important;
    background-color: #f3f4f6;
    justify-content: space-between;

    &-number {
      padding: 8px;
      color: $black;
      font-size: 18px;
      font-weight: 400;
      line-height: 20px;
      border-radius: 5px;
      background-color: $white;
      margin-bottom: 0px !important;
    }

    &-name {
      color: $black;
      max-width: 60%;
      font-size: 18px;
      overflow: hidden;
      font-weight: 400;
      line-height: 20px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &-remove {
      cursor: pointer;
    }
  }
}

.modal-footer {
  border-top: none !important;
  align-items: center !important;
  justify-content: center !important;
  margin-top: 30px;
}

.modal-content{
  padding:30px !important;
}

.modal-body {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 28px 20px !important;

  h4 {
    font-size: 16px;
    font-weight: 800;
    margin-top:10px;
  }

  h5 {
    color: $blackLight;
    font-size: 18px;
    text-align: center;
    text-align: justify;
  }
}
