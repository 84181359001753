@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.search-page {
  padding: 0 40px;
  padding-bottom: 50px;
  min-height: 100vh;
  position: relative;

  &-search {
    margin-top: 24px;

    .search-input {
      width: 100%;
      height: 40px;
      border: none;
      padding: 0 25px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      border-bottom: 1px solid #bed6e1;
      background-color: rgba(242, 246, 248, 0.8);
    }
  }

  &-lessons {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    align-items: center;
    justify-content: space-between;

    .empty-state {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .img {
        max-width: 120px;
      }
    }
  }

  .dividing-line {
    position: absolute;
    height: 80%;
    width: 2px;
    top: 120px;
    left: 50%;
    background-color: rgba($black, 0.1);
    transform: translateX(-50%);
  }

  .lesson-item {
    margin: 8px;
    display: flex;
    padding: 10px;
    cursor: pointer;
    position: relative;
    border-radius: 10px;
    align-items: center;
    align-self: flex-start;
    border: 1px solid $grayLight;
    box-shadow: 2px 2px 10px 2px rgba($black, 0.1);
    background-color: $white;

    &-number {
      padding: 8px;
      position: absolute;
      width: 56px;
      height: 100%;
      border-radius: 15px;
      left: -8px;
      display: flex;
      align-items: center;
      margin: 0px !important;
      justify-content: center;
      font-size: 18px;
      color: $white;
    }

    &-name {
      margin: 0px;
      overflow: hidden;
      font-weight: 600;
      margin-left: 56px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &-disabled {
      opacity: 0.5;
      cursor: default;
    }
  }
}
