@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

.navigation-static {
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	width: 100%;
	z-index: 888;
	display: flex;
	position: fixed;
	max-width: 260px;
	overflow-y: auto;
	padding-top: 36px;
	align-items: center;
	padding-bottom: 48px;
	flex-direction: column;
	background-color: $white;
	justify-content: flex-start;
	box-shadow: 10px 0px 30px rgba(78, 88, 93, 0.05);

	&-brand {
		margin-bottom: 42px;
		text-align: center;
	}

	&-logo {
		// height: 38px;
		max-height: 100px;
		width: 50%;
		min-width: 100px;
		cursor: pointer;
	}

	&-links {
		width: 100%;
		margin-top: 30px;
	}

	.navigation-link {
		display: flex;
		font-size: 14px;
		font-weight: 600;
		color: $blackDark;
		line-height: 20px;
		padding: 10px 32px;
		padding-left: 24px;
		margin-right: 16px;
		padding-bottom: 7px;
		margin-bottom: 16px;
		align-items: center;
		background-color: $white;
		text-transform: uppercase;
		justify-content: flex-start;
		border: 1px solid transparent;
		border-top-right-radius: 25px;
		border-bottom-right-radius: 25px;

		&:last-child {
			margin-bottom: 0px;
		}

		&:active,
		&:hover,
		&:focus {
			text-decoration: none;
		}

		&-active {
			color: $blueDark;
			background: linear-gradient(270deg, rgba(3, 144, 242, 0.18) 0%, rgba(88, 242, 233, 0.12) 100%);
		}

		&-icon {
			width: 20px;
			height: 20px;
			margin-right: 8px;
			margin-bottom: 2px;
		}
	}
}

.profile-preview {
	margin-bottom: 8px;

	&-placeholder {
		width: 96px;
		height: 96px;
	}
}

.profile-name {
	margin: 0px;
	font-size: 22px;
	font-weight: bold;
	line-height: 22px;
	margin-bottom: 4px;
	text-align: center;
}

.profile-class {
	margin: 0px;
	margin-top: 10px;
	font-size: 18px;
	font-weight: 600;
	line-height: 20px;
	margin-bottom: 4px;
	text-align: center;
}

.profile-gamification {
	margin-bottom: 32px;

	&-stars {
		margin: 0px;
		width: 16px;
		height: 16px;
		margin-right: 4px;
		margin-bottom: 3px;
	}

	&-value {
		font-size: 18px;
		font-weight: 600;
		line-height: 20px;
	}
}

.switch-user-container {
	background-color: #f4f7f9;
	padding: 10px 2px;
	margin: 0 10px;
	display: flex;
	align-items: center;
	border-radius: 12px;
	margin-top: 30px;

	&:hover {
		text-decoration: none;
	}

	.user-type-img {
		flex: 0.4;

		img {
			width: 80px;
		}
	}

	.label {
		font-size: 14px;
		text-transform: uppercase;
		font-weight: 600;
		color: $blackDark;
		flex: 0.4;
	}

	.arrow {
		flex: 0.1;
		text-align: right;

		img {
			width: 20px;
		}
	}
}
