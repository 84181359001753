@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';



.mt{
	margin-top:8px;
}

.correct-answer {
	&-title {
		margin-top: 10px;
		font-size: 16px;
		font-weight: 800 !important;
	}

	&-img {
		max-width: 80px;
	}

	&-subtitle {
		// margin-top: 12px;
	}

	h5 {
		margin-bottom: 0;
	}
}

.modal-content {
	overflow-y: auto;
	// max-height: 90vh;
	border-radius: 20px !important;
}

.modal-footer {
	border-top: none !important;
	align-items: center !important;
	padding: 0 !important;
	justify-content: center !important;
	margin-bottom: 0 !important;
}

.modal-body {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	// padding: 0 40px !important;
	// margin-bottom: 10px;

	&-answer {
		width: 100%;
		display: flex;
		padding: 16px;
		margin-bottom: 16px;
		border-radius: 20px;
		flex-direction: column;
		background-color: $grayLight;
		
		p {
			margin-bottom: 8px;
			text-align: justify;
			font-size: 14px;
		}
		
		pre {
			margin-bottom: 8px;
			text-align: justify;
			white-space: pre-wrap;
			font-family: 'Avenir LT Std', sans-serif !important;
			font-size: 14px;
		}

		img {
			max-width: 150px;
			max-height: 150px;
			margin-bottom: 8px;
			border-radius: 8px;
			align-self: center;
		}
	}

	&-explanation {
		width: 100%;
		display: flex;
		padding: 16px;
		margin-bottom: 16px;
		border-radius: 20px;
		flex-direction: column;
		background-color: $grayLight;

		p {
			margin-bottom: 8px;
		}

		img {
			max-width: 80%;
			align-self: center;
			margin: 16px 0;
		}
	}

	.complete-subtitle{
		margin-top:6px;
		margin-bottom: 110px;
	}
}

.resource-complete-btn{
	padding-top:8px;
}

@media (min-width: 576px) {
	.modal-dialog {
		max-width: 700px;
	}
}