@import "../../assets/styles/mixins";
@import "../../assets/styles/variables";

.download-container {
  .onboarding-container-dynamic {
    padding: 0px;
  }

  .download {
    top: 0px;
    left: 0px;
    z-index: 2;
    width: 100%;
    position: absolute;
    padding: calc(58px + 32px) 0px;

    &-left {
      padding: 60px;
      display: flex;
      align-items: center;
      justify-content: center;

      &-icon {
        width: 60px;
        margin: 32px;
      }
    }

    &-right {
      padding: 60px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      &-input {
        border: none;
        margin: 4px 0px;
        padding: 8px 12px;
        margin-bottom: 32px;
        background-color: transparent;
        border-bottom: 1px solid rgba(34, 34, 34, 0.2);
      }
    }

	&-footer {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
  }
}
