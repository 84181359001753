@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.empty-state {
  top: 50%;
  left: 50%;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transform: translate(0, -50%);

  &-img {
    width: 100%;
    height: 100%;
    max-width: 120px;
    margin-bottom: 24px;
  }

  &-label {
    font-size: 18px;
    color: #8892a6;
    margin-top: 20px;
    max-width: 300px;
    font-weight: 400;
    margin: 0px auto;
    text-align: center;
  }
}
