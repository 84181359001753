@mixin largeDisplay {
	@media only screen and (min-width: 1920px) {
		@content;
	}
}

@mixin laptopDisplay {
	@media only screen and (min-width: 1366px) and (max-width: 1680px) {
		@content;
	}
}

@mixin smallLaptopDisplay {
	@media only screen and (min-width: 1280px) and (max-width: 1365px) {
		@content;
	}
}

@mixin tabletDisplay {
	@media only screen and (max-width: 1024px )  {
		@content;
	}
}



@mixin largeLaptopDisplay {
	@media only screen and (min-width: 1440px) {
		@content;
	}
}
