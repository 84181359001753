@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

.subject-progress {
	// max-width: 380px;
	// width: 100%;
	display: flex;
	background-color: $white;
	box-shadow: 0px 10px 40px #00345817;
	border-radius: 14px;
	padding: 16px 22px;

	.subject {
		&-img {
			flex: 0.3;
			img {
				border-radius: 12px;
			}
		}

		&-info {
			flex: 0.7;
			margin-left: 20px;
		}

		&-name {
			font-size: 20px;
			font-weight: 600;
			color: $blackDark;
		}
	}

	&-bar {
		background-color: #e5e8ec;
    width:100%;
		height: 7px;
		border-radius: 5px;
		position: relative;
		margin-top: 14px;

		.progress-value {
			position: absolute;
			height: 100%;
			background-color: $blueDark;
			border-radius: 12px;
		}
	}

	&-count {
		display: flex;
		align-items: center;
		margin-top: 14px;

		h3 {
			margin: 0;
			font-size: 24px;
			font-weight: 700;
		}

		.numerator {
			color: $blueDark;

			&:after {
				content: '/';
				color: $blackDark;
				margin: 0 4px;
				font-weight: 200;
			}
		}

		.denominator {
			color: rgba($blueDark, 0.7);
		}

		p {
			margin-bottom: 0;
			margin-left: 12px;
		}
	}
}
