@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.subject-icon {
	width: 150px;
	min-height: 142px;
	box-shadow: 0px 10px 40px #00345817;
	position: relative;
	border-radius: 20px;
	background-color: $white;
	color: #5f5f5f;

	.wrapper {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
	}

	.icon-wrap {
		text-align: center;
	}

	.label {
		text-align: center;
		margin-top: 15px;
		font-weight: 900;
		font-size: 18px;
	}
}
