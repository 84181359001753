@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.rqt-container {
	width: 100%;
	display: flex;
	flex-direction: column;

	.content-scroller {
		display: flex;
		overflow-y: auto;
		max-height: calc(100vh - 80px);
	}

	&-content {
		width: 100%;
		padding: 24px 40px;
	}

	&-footer {
		display: flex;
    	margin-top: 32px;
		padding: 32px 40px;
		align-items: center;
		justify-content: space-between;

		p {
			color: $black;
			font-size: 16px;
			font-weight: 600;
			line-height: 22px;
		}
	}
}

.rqt-overlay {
	opacity: 1 !important;
	background-color: $grayLight !important;
}

.reading-title {
	font-size: 16px;
	font-weight: bold;
	line-height: 26px;
	color: $blackDark;
	margin-top: 10px;
}

.reading-illustration {
	width: 80px;
	height: 80px;
	margin: 0px auto;
}

.reading-description {
	max-width: 75%;
	font-size: 14px;
	margin: 0px auto;
	line-height: 20px;
	margin-top: 2px;
	color: $blackLight;
	text-align: center;
}

	.rqt-contiue-btn {
		margin-top: 72px;
	}